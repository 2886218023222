<template>
    <div class="invites-page">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">

            <section class="client-space client-space-home">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <InvitesHistory />
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import InvitesHistory from '@/components/InvitesHistory.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            ClientSpaceResume,
            InvitesHistory
        },

        beforeMount(){
            this.loginStatus()
        },

        data() {
            return {
                flagSelected: "en"
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space");
                document.head.querySelector('meta[name=description]').content = ''
            },

            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />