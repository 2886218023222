<template>
    <div class="pending">
        <div id="dne"></div>
        <Header />

        <section class="kyc-pending">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <!-- BOX ERROR -->
            <div class="container" v-if="showContent && errorsGet.dataPending != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>
                    
                    <p>
                        {{ getMyLanguage("box-error", errorsGet.dataPending) }}
                    </p>

                    <div class="cod-error">
                        Cód.: {{errorsGet.dataPending}}
                    </div>
                </div>
            </div>

            <!-- PENDING CONTENT -->
            <div class="container" v-if="showContent">                
                <h2 class="title-secondary">
                    {{getMyLanguage("kyc","pending.title")}}
                </h2>
                
                <div class="box-pending">
                    <p>{{getMyLanguage("kyc","pending.msg")}}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiInternational from '@/config/apiInternational.js'
  
    export default {
        components: {
            Header
        },

        data(){
            return{
                showContent: false,
                dataPending: {},

                errorsGet: {
                    dataPending: false
                }
            }
        },    
        
        
        async mounted(){
            await apiInternational.get('/api/v1/platform/account/international/kyc')
            .then(response => {
                this.dataPending = response.data

                this.modalKYC = true,
                this.showContent = true
            })
            .catch(error => {
                console.log(error.response.status)
                this.errorsGet.dataPending = error.response.status
                this.showContent = true
            })
        },


        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.pending");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="@/views/ClientSpace/custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-pending.scss" lang="scss" scoped />