<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">
            <section class="client-space">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <a id="anchor-content"></a>
            
            <section class="purchase-pay-error">                
                <div class="container">

                    <div class="row">
                        <ul class="breadcrumb">
                            <li class="active"><a :href="`/${flagSelected}/client-space`"><span>{{ getMyLanguage("client-space", "client-space.resume.client-space") }}</span></a></li>
                            <li><a :href="`/${flagSelected}/client-space/purchase`"><span>{{ getMyLanguage("client-space", "client-space.increase-cta") }}</span></a></li>
                        </ul>
                    </div>

                    <!-- ERROR -->
                    <div class="row">
                        <div class="col-12 error">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            <div class="box-error-card">
                                <img src="~@/assets/images/icons/alert.svg">
                                <h3 v-html='getMyLanguage("error-purchase",msgError)'></h3>
                            </div>
                        </div>
                    </div>

                    <!-- ACTION -->
                    <div class="row ">
                        <div class="col-12 action">
                            <a :href="`/${flagSelected}/client-space/purchase`" class="btn-another-method"><span v-html='getMyLanguage("error-purchase-action", "another-method")'></span></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            ClientSpaceResume,
        },

        data() {
            return {
                msgError: ""
            }
        },

        async mounted(){
            this.purchaseError()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.purchase");
                document.head.querySelector('meta[name=description]').content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            purchaseError(){
                const urlParams = new URLSearchParams(window.location.search);
                const errorPurchase = urlParams.get('error');
                console.log(errorPurchase)

                if ( errorPurchase === "third_party_stripe_create_account_failure" || errorPurchase === "third_party_stripe_create_payment_intent_failure" || errorPurchase === "third_party_swapix_payment_failure" ){
                    this.msgError = errorPurchase
                } else{
                    this.msgError = "general_error"
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.purchase");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpacePurchaseError.scss" lang="scss" scoped />