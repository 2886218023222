<template>
    <div class="row-menu">
        <div class="openMenuMobile" @click="showMenuMobile = true"></div>

        <div class="menu" :class="{active: showMenuMobile}">

            <div class="entity hasAnchors" :class="{opened: showMenu}">
                <div class="openMenu" @click="showMenu = !showMenu"></div>
                <i>{{ getMyLanguage("page-active","you-are-in") }}</i>
                <h2>DNE <span>T</span>oken</h2>
            </div>

            <ul class="menu-anchors" :class="{active: showMenu}" v-if="flagSelected">
                <li><a :href="`/${flagSelected}#dne`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-home">{{ getMyLanguage("purchase-token", "purchase.menu.home") }}</a></li>
                <!-- <li class="purchase-token"><a :href="`https://dnetoken.com/${flagSelected}`">{{ getMyLanguage("purchase-token", "purchase.menu.purchase") }}</a></li> -->
                <li><a :href="`/${flagSelected}#concept`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-concept">{{ getMyLanguage("purchase-token", "purchase.menu.concept") }}</a></li>
                <li><a :href="`/${flagSelected}#roadmap`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-roadmap">{{ getMyLanguage("purchase-token", "purchase.menu.roadmap") }}</a></li>
                <li><a :href="`/${flagSelected}#distribution`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-distribution">{{ getMyLanguage("purchase-token", "purchase.menu.distribution") }}</a></li>
                <li><a :href="`/${flagSelected}#team`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-team">{{ getMyLanguage("purchase-token", "purchase.menu.team") }}</a></li>
                <li><a :href="`/${flagSelected}#faq`" @click="showMenu = false, showMenuMobile = false" class="lnk-menu-faq">{{ getMyLanguage("purchase-token", "purchase.menu.faq") }}</a></li>
                <li><a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/support`" class="lnk-menu-support" target="_blank">{{ getMyLanguage("purchase-token", "purchase.menu.support") }}</a></li>
            </ul>
            
            <ul class="menu-entities">
                <div class="entity get-to-know">
                    <i>{{ getMyLanguage("page-active","get-to-know") }}</i>
                    <h2>{{ getMyLanguage("client-space","client-space.home.title") }}</h2>
                </div>

                <!-- <li><a :href="`/${flagSelected}/ico`" class="lnk-ico"><h2><span>I</span>CO</h2></a></li> -->
                <li><a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/`"><h2>DNE <span>E</span>cosystem</h2></a></li>
                <li><a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/market`"><h2>DNE <span>M</span>arket</h2></a></li>
                <li><a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/pay`"><h2>DNE <span>P</span>ay</h2></a></li>
                <li><a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/exchange`"><h2>DNE <span>E</span>xchange</h2></a></li>
            </ul>
        </div>
    </div>

    <div class="closeMenuMobile" v-if="showMenuMobile" @click="showMenuMobile = false"></div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data(){
            return {
                showContent: false,
                flagSelected: "en",

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,

                showMenuMobile: false,
                showMenu: false,
            }
        },

        async mounted(){
            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .row-menu{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }

        *{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .openMenuMobile{
        @media (min-width: 1200px){
            display: none;
        }
        
        @media (max-width: 1200px){
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: url('~@/assets/images/icons/menuMobile.svg') no-repeat center $color-text-tertiary;
            background-size: 16px auto;
            border: 1px solid #eee;
            transition: .3s;
            cursor: pointer;

            &:hover{
                background-color: #e5e5e5;
            }
        }
    }

    .menu{
        @media (min-width: 1200px){
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 330px){
            padding: 2.5rem 1rem;
        }

        @media (min-width: 330px) and (max-width: 1200px){
            padding: 3rem 2rem;
        }

        @media (max-width: 1200px){
            @include scrollbars(8px, $color-secondary, #ddd);
            overflow-y: auto;
            position: fixed;
            z-index: 9999;
            top: 0%;
            left: -360px;
            width: calc(100% - 40px);
            height: 100vh;
            max-width: 360px;
            background: #ddd;

            &.active{
                transition: 1s;
                left: 0;

                .entity{
                    @media (max-width: 1200px){
                        display: block;
                    }
                }
            }
        }

        h2{
            padding: 0 1rem;
            margin: 0;
            white-space: nowrap;
            border-radius: 12px;
            transition: .3s;

            @media (max-width: 1200px){
                font-size: 16px;
                line-height: 40px;
            }

            @media (min-width: 1200px){
                font-size: 16px;
                line-height: 36px;
            }

            span{
                &:after{
                    height: 3px;
                }
            }
        }

        .entity{
            position: relative;

            @media (min-width: 1200px){
                margin-right: 3rem;
            }

            @media (max-width: 1200px){
                display: none;
            }

            .openMenu{
                @media (max-width: 1200px){
                    display: none;
                }

                @media (min-width: 1200px){
                    display: block;
                    position: absolute;
                    z-index: 20;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }

            i{
                display: block;
                font-style: normal;
                font-size: 10px;
                white-space: nowrap;
                position: absolute;
                z-index: 10;

                @media (max-width: 1200px){
                    @include shadown;
                    line-height: 20px;
                    color: $color-text-tertiary;
                    padding: 0 10px;
                    border-radius: 12px;
                    background: #aaa;
                    top: -17px;
                    left: .5rem;
                }

                @media (min-width: 1200px){
                    top: -15px;
                    left: 1rem;
                    line-height: 12px;
                }
            }

            h2{
                background: #eee;

                @media (max-width: 1200px){
                    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
                }
            }


            &.hasAnchors{
                @media (min-width: 1200px){
                    h2{
                        position: relative;
                        padding-right: 2.5rem;

                        &:after{
                            content: '';
                            display: block;
                            position: absolute;
                            right: 1rem;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 0;
                            height: 0;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-top: 6px solid $color-primary;
                        }
                    }

                    &:hover, &.opened{
                        h2{
                            background-color: #ddd;
                        }
                    }
                }
            }
        }


        // MENU ANCHORS
        .hasAnchors + .menu-anchors.active{
            @media (min-width: 1200px){
                display: block;
            }
        }
        
        .hasAnchors + .menu-anchors{
            @media (max-width: 1200px){
                display: block;
            }
        }

        .menu-anchors{
            display: none;

            @media (max-width: 1200px){
                @include shadown;
                padding: calc(1rem + 12px) 1.5rem 1rem 1.5rem;
                margin: -12px auto 1rem;
                width: calc(100% - 1rem);
                border-radius: 12px;
                background: #f5f5f5;
            }

            @media (min-width: 1200px){
                @include shadown;
                background: $color-text-tertiary;
                position: absolute;
                left: 0;
                top: calc(100% + 10px);
                padding: 1rem;
                border-radius: 12px;
                animation: showMenuAnchors .3s alternate;

                &.active{
                    display: block;
                }

                @keyframes showMenuAnchors {
                    0% {opacity: 0; top: calc(100% + 20px);}
                    100% {opacity: 1; top: calc(100% + 10px);}
                }
            }

            li{
                a{
                    font-size: 14px;
                    line-height: 36px;
                    color: $color-primary;
                    padding: 0 45px 0 40px;
                    white-space: nowrap;
                    border-bottom: 1px solid $color-tertiary;
                    position: relative;

                    &:before, &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        transform: translateY(-50%);
                    }

                    &:before{
                        left: 5px;
                        top: 50%;
                        width: 24px;
                        height: 24px;
                        border-radius: 3px;
                        background-color: #eee;
                        background-size: 16px auto !important;
                    }

                    &.lnk-menu-home{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-home.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-concept{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-concept.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-products{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-products.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-platform{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-platform.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-ecosystem{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-ecosystem.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-download-app{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-download-app.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-support{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-support.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-roadmap{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-roadmap.svg') no-repeat center #eee;
                        }
                    }
                    
                    &.lnk-menu-distribution{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-distribution.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-team{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-team.svg') no-repeat center #eee;
                        }
                    }

                    &.lnk-menu-faq{
                        &:before{
                            background: url('~@/assets/images/icons/lnk-menu-faq.svg') no-repeat center #eee;
                        }
                    }

                    &:after{
                        right: 5px;
                        top: 50%;
                        width: 6px;
                        height: 10px;
                        background: url('~@/assets/images/icons/arrow-btn-menu-mobile.svg') no-repeat center;
                        background-size: auto 100%;
                        transition: .3s;
                    }

                    &:hover{
                        &:after{
                            right: 0;
                        }
                    }
                }

                &:last-child{
                    a{
                        border-bottom: none;
                    }
                }
            }
        }


        // MENU ENTITIES
        .menu-entities{
            @media (max-width: 1200px){
                @include shadown;
                position: relative;
                padding: calc(1rem + 40px) 1.5rem 1rem 1.5rem;
                margin: 4rem auto 1rem;
                width: calc(100% - 1rem);
                border-radius: 12px;
                background: #f5f5f5;

                .get-to-know{
                    position: absolute;
                    top: 0;
                    left: -0.5rem;
                    width: calc(100% + 1rem);
                }

                li{
                    a{
                        padding: 0 2.5rem 0 38px;
                        white-space: nowrap;
                        border-bottom: 1px solid $color-tertiary;
                        position: relative;

                        &:before, &:after{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &:before{
                            left: 0;
                            width: 28px;
                            height: 22px;
                            border-right: 1px solid #ccc;
                            background: url('~@/assets/images/icons/dne.svg') no-repeat left center;
                            background-size: auto 22px;
                        }

                        &:after{
                            width: 6px;
                            height: 10px;
                            background: url('~@/assets/images/icons/arrow-btn-menu-mobile.svg') no-repeat center;
                            background-size: auto 100%;
                            right: 10px;
                        }

                        h2{
                            padding: 0;
                        }

                        &:hover{
                            &:after{
                                right: 5px;
                            }
                        }
                    }

                    &:last-child{
                        a{
                            border-bottom: none;
                        }
                    }
                }
            }

            @media (min-width: 1200px){
                display: flex;
                flex-wrap: nowrap;

                h2{
                    &:hover{
                        background: #efefef;
                    }
                }
                
                .get-to-know{
                    display: none;
                }
            }

            .lnk-ico{
                @media (max-width: 1200px){
                    background: $color-secondary;
                    border-radius: 12px;
                    margin: .5rem 0;
                    text-align: center;

                    &:before, &:after{
                        display: none;
                    }
                }

                @media (min-width: 992px){
                    &:hover{
                        h2{
                            background: $color-primary;

                            span{
                                &:after{
                                    background: $color-secondary;
                                }
                            }
                        }
                    }
                }

                h2{
                    background: $color-secondary;
                    color: $color-text-tertiary;
                    
                    span{
                        color: $color-text-tertiary;
                        
                        &:after{
                            background: $color-primary;
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }

    .closeMenuMobile{
        @media (max-width: 1200px){
            position: fixed;
            z-index: 9990;
            right: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-size: 24px auto !important;
            background: url("~@/assets/images/icons/close-white.svg") no-repeat rgba($color: #000000, $alpha: 0.75);
            cursor: pointer;
        }

        @media (max-width: 576px){
            animation: closeOverlaySmall 1.2s alternate;
            background-position: calc(100% - 8px) 1rem;
        }
        
        @media (min-width: 576px){
            animation: closeOverlay 1.2s alternate;
            background-position: 380px 1rem;
        }
    }

    @keyframes closeOverlaySmall {
        0% {background-position: calc(100% + 40px) 1rem}
        75% {background-position: calc(100% + 40px) 1rem}
        100% {background-position: calc(100% - 8px) 1rem;}
    }
    
    @keyframes closeOverlay {
        0% {background-position: -40px 1rem}
        75% {background-position: -40px 1rem}
        100% {background-position: 380px 1rem;}
    }
</style>