<template>
    <!-- <div class="col-12 all-phases" :class='{phase_current: dataPhaseCurrent.phase_type != "Private"}'> -->
    <div class="col-12 all-phases" :class="dataPhaseCurrent.phase_type">
        <div class="row row-titles-phases">
            <div class="col-12 col-lg-3 col-private">
                <h2 class="title-phase-private">{{getMyLanguage("purchase-token", "phase-private.nameGroupPhasePrivate")}}</h2>
            </div>

            <div class="col-12 col-lg-9">
                <h2 class="title-phase-public">{{getMyLanguage("purchase-token", "phase-private.nameGroupPhasePublic")}}</h2>
            </div>
        </div>

        <div class="row row-content-phases">

            <!-- PRIVATE SALE -->
            <h2 class="title-phase-private">{{getMyLanguage("purchase-token", "phase-private.nameGroupPhasePrivate")}}</h2>
            <div class="col-12 col-lg-3 col-private">
                <div class="phase" :class="dataPhasePrivate.status_extra">
                    <div class="load" v-if="!phaseShow"></div>

                    <div class="phase-cont" v-if="phaseShow">
                        <h3 class="open-phase-complete">{{getMyLanguage("purchase-token", "phase-public.name." + dataPhasePrivate.phase_name)}}</h3>
                        <h3>{{getMyLanguage("purchase-token", "phase-public.name." + dataPhasePrivate.phase_name)}}</h3>
                        <div class="dateStart">{{ dateTime(dataPhasePrivate.phase_start_date) }}</div>
                        <p class="price-for"><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.package")}} {{dataPhasePrivate.product_quantity}} tokens:</span> <strong>{{dataPhasePrivate.purchase_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</strong></p>
                        
                        <div class="phase-cont-complete">
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.minAmount")}}:</span> <strong>{{dataPhasePrivate.phase_min_amount}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.maxAmount")}}:</span> <strong>{{dataPhasePrivate.phase_max_amount_person}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.dateStart")}}:</span> <strong>{{ dateTime(dataPhasePrivate.phase_start_date) }}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.dateEnd")}}:</span> <strong>{{ dateTime(dataPhasePrivate.phase_end_date) }}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.maxSelling")}}:</span> <strong>{{dataPhasePrivate.phase_max_quantity.toLocaleString()}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>

                            <div v-if="dataPhaseCurrent.phase_type == 'Private'" @click="lnkRegister()" class="btn-secondary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}</div>
                            
                            <!-- <a v-if="$store.state.statusLogin === false && dataPhasePrivate.status_extra == 'phase_current'" :href='VUE_APP_PLATFORM_URL + flagSelected + "/account/register"' class="btn-secondary" target="_blank">{{ getMyLanguage("login","login.register-now") }}</a>
                            <a v-if="$store.state.statusLogin === true && dataPhasePrivate.status_extra == 'phase_current'" :href='"/" +  flagSelected + "/client-space/purchase/"' class="btn-secondary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.purchaseNow") }}</a> -->
                        </div>
                    </div>
                </div>
            </div>


            <!-- ICO PUBLIC -->
            <h2 class="title-phase-public">{{getMyLanguage("purchase-token", "phase-private.nameGroupPhasePublic")}}</h2>
            <div class="col-12 col-lg-3" v-for="(itemPhase, index) in dataPhasePublic" :key="index">
                <div class="phase" :class="itemPhase.status_extra">
                    <div class="load" v-if="!phaseShow"></div>

                    <div class="phase-cont" v-if="phaseShow">
                        <h3 class="open-phase-complete">{{getMyLanguage("purchase-token", "phase-public.name." + itemPhase.phase_name)}}</h3>
                        <h3>{{getMyLanguage("purchase-token", "phase-public.name." + itemPhase.phase_name)}}</h3>
                        <div class="dateStart">{{ dateTime(itemPhase.phase_start_date) }}</div>
                        <p class="price-for"><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.package")}} {{itemPhase.product_quantity}} tokens:</span> <strong>{{itemPhase.purchase_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</strong></p>
                        
                        <div class="phase-cont-complete">
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.bonus")}}:</span> <strong class="dne">{{itemPhase.purchase_bonus}}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.minAmount")}}:</span> <strong>{{itemPhase.phase_min_amount}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.maxAmount")}}:</span> <strong>{{itemPhase.phase_max_amount_person}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.dateStart")}}:</span> <strong>{{ dateTime(itemPhase.phase_start_date) }}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.dateEnd")}}:</span> <strong>{{ dateTime(itemPhase.phase_end_date) }}</strong></p>
                            <p><span>{{getMyLanguage("purchase-token", "purchase-token.content.phase.maxSelling")}}:</span> <strong>{{itemPhase.phase_max_quantity.toLocaleString()}} {{getMyLanguage("purchase-token", "purchase-token.content.phase.packages")}}</strong></p>

                            <a v-if="itemPhase.status_extra == 'phase_current'" :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/account/register`" class="btn-secondary" target="_blank">{{ getMyLanguage("login","login.register-now") }}</a>
                            <!-- <a v-if="$store.state.statusLogin === false && itemPhase.status_extra == 'phase_current'" :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/account/register`" class="btn-secondary" target="_blank">{{ getMyLanguage("login","login.register-now") }}</a>
                            <a v-if="$store.state.statusLogin === true && itemPhase.status_extra == 'phase_current'" :href="`/${flagSelected}/client-space/purchase/`" class="btn-secondary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.purchaseNow") }}</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $
    
    // PHASES
    $(document).on('click','.phases .phase:not(".phase_current") .phase-cont .open-phase-complete', function(){
        $(this).toggleClass('openPhase');
        //   $(this).next().next().slideToggle();
        $(this).next().next().next().next().slideToggle();
        // $(this).next().find('.phase-cont-complete').slideToggle();
    })

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js'

    import moment from 'moment';

    export default{
        data: function () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,

                flagSelected: "en",
                dataPhaseCurrent: "",
                dataPhasePublic: [],
                dataPhasePrivate: {},
                phaseShow: false,
            }
        },

        async mounted(){
            const resumePhase = await apiInternational.get('api/v2/ico/phase/')
            this.dataPhasePrivate = resumePhase.data.filter(priv => priv.phase_type === "Private")[0]
            this.dataPhasePublic = resumePhase.data.filter(pub => pub.phase_type === "Public")
            this.dataPhaseCurrent = resumePhase.data.filter(curr => curr.status_extra === "phase_current")[0]

            // const resumePhaseCurrent = await apiInternational.get('api/v2/ico/phase/current/')
            // this.dataPhaseCurrent = resumePhaseCurrent.data


            //  this.phasePrivateString()
            this.phaseShow = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                if ( this.$store.state.SelectLanguage.code ){
                    this.flagSelected = this.$store.state.SelectLanguage.code
                }
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },


            
            // dateTime(value) {
            //     const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
            //     return moment.utc(value).locale(format).format('ll');
            // },

            // phasePrivateString() {
            //     let price = this.dataPhasePrivate.finalPrice
            //     let maxSelling = this.dataPhasePrivate.maxQuantity
                
            //     maxSelling = maxSelling.toLocaleString()
            //     price = price.toLocaleString(undefined, { minimumFractionDigits: 2 })
                
            //     // let total = val02.toLocaleString('pt-BR')

            //     this.dataPhasePrivate.finalPrice = price
            //     this.dataPhasePrivate.maxQuantity = maxSelling
            // },

            lnkRegister(){
                let urlRegister
                
                if ( this.$store.state.phaseCurrent.phase_type == "Private" ){
                    urlRegister = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/private-sale`
                } else {
                    urlRegister = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register/`
                }

                window.open(urlRegister, "_blank")
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style src="@/assets/scss/_phases.scss" lang="scss" scoped />
