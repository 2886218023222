<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">
            <section class="client-space">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <a id="anchor-content"></a>

            <div class="load load-200" v-if="!showContent"></div>
            
            <section class="purchase-pay" v-if="showContent">
                
                <div class="container">
                    <div class="row">
                        <ul class="breadcrumb">
                            <li class="active">
                                <a :href="`/${flagSelected}/client-space`">
                                    <span>{{ getMyLanguage("client-space", "client-space.resume.client-space") }}</span>
                                </a>
                            </li>
                            
                            <li>
                                <a :href="`/${flagSelected}/client-space/wallet-stake`">
                                    Wallet Stake
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="row wallet-stake">
                        <div class="col-12">
                            <h2 class="title-primary">
                                <span>W</span>allet Stake
                            </h2>

                            <!-- GENERATE WALLET -->
                            <div class="box box-wallet" :class="{active: flow.generated}" v-if="!flow.error">
                                <p class="alert" v-if="!flow.generated" v-html='getMyLanguage("purchase-token","wallet-stake.alert-01")'></p>
                                <p class="alert" v-if="flow.generated" v-html='getMyLanguage("purchase-token","wallet-stake.alert-02")'></p>

                                <div class="load load-200" v-if="flow.load"></div>
                                
                                <div class="btn-primary" v-if="!flow.generated && !flow.load" @click="generateWallet()">
                                    {{ getMyLanguage("purchase-token","wallet-stake.btn-generate-wallet") }}
                                </div>

                                <div class="my-wallets" v-if="flow.generated && !flow.load">
                                    <div class="wallet">
                                        <p>
                                            <strong>{{ getMyLanguage("purchase-token","wallet-stake.public-key") }}</strong>
                                        </p>
                                        
                                        <div class="wallet-copy">
                                            <small>{{flow.keys.public}}</small>
                                            <span class="copy" @click="copyWallet(flow.keys.public,flow.copied.public = true)"></span>
                                        </div>
                                    </div>

                                    <div class="wallet">
                                        <p>
                                            <strong>{{ getMyLanguage("purchase-token","wallet-stake.secret-key") }}</strong>
                                        </p>

                                        <div class="wallet-copy">
                                            <small>{{flow.keys.secret}}</small>
                                            <span class="copy" @click="copyWallet(flow.keys.secret,flow.copied.secret = true)"></span>
                                        </div>

                                        <p>
                                            <small v-html='getMyLanguage("purchase-token","wallet-stake.alert-03")'></small>
                                        </p>
                                    </div>
                                </div>

                                <div class="accept" v-if="flow.generated && !flow.load">
                                    <div class="form-group-check">
                                        <input type="checkbox" id="confirm" class="form-check" v-model="flow.confirm">
                                        
                                        <label for="confirm" class="form-check-label">
                                            {{ getMyLanguage("purchase-token","wallet-stake.certify") }}
                                        </label>
                                    </div>
                                </div>

                                <div class="no-copied" v-if="flow.confirm && (!flow.copied.public || !flow.copied.secret)">
                                    <p v-if="!flow.copied.public">
                                        {{ getMyLanguage("purchase-token","wallet-stake.no-copied.public") }}
                                    </p>

                                    <p v-if="!flow.copied.secret">
                                        {{ getMyLanguage("purchase-token","wallet-stake.no-copied.secret") }}
                                    </p>
                                </div>

                                <div class="btn-primary btn-disabled" v-if="flow.generated && !flow.confirm && !flow.load">
                                    {{ getMyLanguage("purchase-token","wallet-stake.continue") }}
                                </div>

                                <div class="btn-primary"
                                v-if="flow.generated && flow.confirm && flow.copied.public && flow.copied.secret && !flow.load"
                                @click="saveWallet()">

                                    {{ getMyLanguage("purchase-token","wallet-stake.continue") }}
                                </div>
                            </div>


                            <!-- ERROR -->
                            <div class="box-error" v-if="flow.error">
                                <h3 class="title-secondary">
                                    {{ getMyLanguage("box-error", "title-error") }}
                                </h3>

                                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>


    <!-- MODAL 2FA -->
    <div class="modal modal-2fa" v-if="modal2FA.show">
        <div class="modal-cont">
            <div class="modal-close" @click="modal2FA.show = false"></div>
            <div class="box">
                <div class="modal-scroll">

                    <!-- SMS CODE -->
                    <div class="sms-code" v-if="!flowSMS.success">
                        <h3 class="title-secondary">
                            {{getMyLanguage("compliance","compliance.modal2fa.title")}}
                        </h3>

                        <div class="load" v-if="flowSMS.load"></div>

                        <p v-if="!flowSMS.load">
                            {{getMyLanguage("compliance","compliance.modal2fa.inf")}}
                        </p>

                        <div class="sms-code-cont" v-if="!flowSMS.load">
                            <!-- <div class="sms-confirm" v-if="flowSMS.smsCodeShow && !flowSMS.load"> -->
                            <div class="sms-confirm">
                                <input type="text" class="form form-smsCode" v-model="codeSMS.smsCode" v-mask="'000000'" placeholder="______" autocomplete="one-time-code">

                                <div class="confirm btn-disable" v-if="codeSMS.smsCode.length < 6">
                                    {{getMyLanguage("wallets","modal-send-code.btn-confirm")}}
                                </div>

                                <div class="confirm" v-if="codeSMS.smsCode.length === 6" @click="validateCodeSMS()">
                                    {{getMyLanguage("wallets","modal-send-code.btn-confirm")}}
                                </div>
                            </div>
                            
                            <div class="alert" v-if="flowSMS.smsCodeShow && !flowSMS.load">
                                {{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_country_ddi}} {{$store.state.profile.profile_phone_number}}
                            </div>

                            <div class="load" v-if="flowSMS.smsCodeShow && flowSMS.load"></div>
                        </div>

                        <div class="resend" v-if="flowSMS.smsCodeShow && !flowSMS.load">
                            <div class="btn-resend resend-load" v-if="!flowSMS.btnResendSMS">
                                {{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSMS.countDownResendCode}}s
                            </div>

                            <div class="btn-resend" v-if="flowSMS.btnResendSMS" @click="getCodeSend()">
                                {{getMyLanguage("wallets","modal-send-code.btn-resend")}}
                            </div>
                        </div>
                    </div>

                    <!-- SUCCESS -->
                    <div class="success" v-if="flowSMS.success && !flowSMS.load">
                        <h3 class="title-secondary">
                            {{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.title")}}
                        </h3>
                        
                        <div class="box-success">
                            <p>{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.msg")}}</p>
                            
                            <a :href="`/${flagSelected}/client-space/purchase`" class="btn-primary">
                                {{getMyLanguage("compliance","compliance.cta-success")}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    
    import apiInternational from '@/config/apiInternational.js'
    import apiPay from '@/config/apiPay.js'
    import { handleError } from '@/services/errorHandler.js'

    export default {
        components: {
            Header,
            ClientSpaceResume
        },
        
        beforeMount(){
            this.loginStatus()
        },

        data() {
            return {
                load: false,
                showContent: false,
                flagSelected: "en",

                flow: {
                    generated: false,
                    load: false,
                    confirm: false,
                    copied: {
                        public: false,
                        secret: false
                    },
                    keys: {
                        public: "",
                        secret: ""
                    },
                    error: false
                },

                saveWalletStake: {
                    asset_short: "DNE",
                    name: "DNE External Wallet Stake",
                    address: "",
                    memo: "",
                    _2fa_token: 0
                },

                codeSMS: {
                    smsCode: ''
                },

                modal2FA: {
                    show: false,
                    load: false,
                    send: false
                },

                flowSMS: {
                    load: true,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    countDownResendCode: 0,
                    success: false
                }
            }
        },

        async mounted(){
            await apiInternational.get('api/v1/international/compliance/wallet/stake')
            .then(response => {
                this.showContent = true
                console.log(response.data)
            })
            .catch(error => {
                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.purchase");
                document.head.querySelector('meta[name=description]').content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            generateWallet(){
                this.flow.load = true

                const StellarSdk = require("stellar-sdk");

                const pair = StellarSdk.Keypair.random();
                this.flow.keys.public = pair.publicKey()
                this.flow.keys.secret = pair.secret()

                this.saveWalletStake.address = pair.publicKey()

                setTimeout(() => {
                    this.flow.generated = true
                    this.flow.load = false
                }, 1000);
            },


            async copyWallet(value) {
                try {
                    await navigator.clipboard.writeText(value);
                    this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                    setTimeout(this.$toast.clear, 3000)

                }
                
                catch($e) {
                    this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                    setTimeout(this.$toast.clear, 3000)
                }
            },


            async getCodeSend(){
                this.flowSMS.load = true
                this.modal2FA.show = true

                apiInternational.post('',this.codeSMS.smsCode)
                .then(response => {
                    console.log(response)

                    this.flowSMS.smsCodeShow = false
                    
                    setTimeout(() => {
                        this.flowSMS.smsCodeShow = true
                        this.flowSMS.load = false
                        this.flowSMS.btnResendSMS = false
                        this.flowSMS.countDownResendCode = 10
                        this.countDownflowSMS()
                    }, 1000);
                })
                .catch(error => {
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.error.codigo"))
                    console.log(error)

                    this.flowSMS.load = false
                })

                // TEMP
                // setTimeout(() => {
                //     this.flowSMS.load = false
                // }, 500);
                
                // this.flowSMS.smsCodeShow = true
                // this.flowSMS.btnResendSMS = false
                // this.flowSMS.countDownResendCode = 10
                // this.countDownflowSMS()
            },


            countDownflowSMS() {

                if(this.flowSMS.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSMS.countDownResendCode -= 1
                        this.countDownflowSMS()
                    }, 1000)
                } else{
                    this.flowSMS.btnResendSMS = true
                }
            },


            async validateCodeSMS(){
                this.flowSMS.load = true
                
                await apiInternational.post('', this.codeSMS)
                .then(response => {
                    console.log(response)

                    setTimeout(() => {
                        this.flowSMS.success = true
                        this.flowSMS.load = false
                        this.errors.invalidCode = false

                        this.saveWallet()
                    }, 1000);
                })
                .catch(error => {
                    this.$toast.error(this.getMyLanguage("client-space","client-space.account.lbl.phone.validate-error"));
                    setTimeout(this.$toast.clear, 6000)

                    console.log(error)
                    
                    this.flowSMS.load = false

                    setTimeout(() => {
                        this.errors.flow = false
                    }, 5000);
                })
            },


            saveWallet(){
                this.flow.load = true

                apiPay.post('/api/v2/pay/wallet/address/trust/stake', this.saveWalletStake)
                .then(response => {
                    console.log(response.data)

                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/client-space/purchase`
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)

                    setTimeout(() => {
                        this.flow.load = false
                        this.flow.error = true
                    }, 1000);
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.purchase");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpaceWalletStake.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_colors.scss';

    .wallet-stake .box-wallet{
        span{
            display: block;
            padding-top: 10px;
            color: $color-pending;
            font-style: italic;
        }
    }
</style>