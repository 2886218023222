<template>
    <div class="ico-public-sale">
        <div id="dne"></div>
        <Header />


        <!-- DNE ECOSYSTEM -->
        <section class="dne-ecosystem">
            <div class="container-fluid">
                <h1 class="title-secondary">DNE TOKEN<strong>ICO - PUBLIC SALE</strong></h1>

                <div class="row row-ecosystem">
                    <div class="col-12 col-sm-10 col-lg-10">
                        <div class="box">
                            <div class="video">
                                <iframe src="https://www.youtube.com/embed/j9Bkzz8bYBs?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


         <!-- ABOUT DNE -->
        <section class="about">
            <div class="container-fluid">
                <div class="row row-dne-token">
                    <div class="col-lg-2 col-lg-3 col-image">
                        <div class="about-image"></div>
                    </div>

                    <div class="col-12 col-lg-9 col-about">
                        <div class="cont-about">
                            <div>
                                <h2><span>DNE</span> Token</h2>
                                <div v-html='getMyLanguage("lp-ico","lp.about.txt")'></div>
                            </div>

                            <div class="bts-about">
                                <h4>Downloads</h4>

                                <a :href="`https://cdn.dneecosystem.com/docs/marketing/DNE-Ecosystem-${flagSelected}.pdf?v=21943`" target="_blank" class="btn-download presentation mb">
                                    <span>
                                        {{ getMyLanguage("lp-ico","lp.about.btn-presetation") }}
                                        <small>PDF Download</small>
                                    </span>
                                </a>
                                
                                <a :href="`https://cdn.dneecosystem.com/docs/WhitePaper-${flagSelected}.pdf?v=87514`" target="_blank" class="btn-download">
                                    <span>
                                        Whitepaper
                                        <small>PDF Download</small>
                                    </span>
                                </a>

                                <h4 class="title-registers">{{ getMyLanguage("lp-ico","lp.about.tit-registers") }}</h4>

                                <a href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search" target="_blank" class="btn-link mb">
                                    <span>
                                        {{ getMyLanguage("lp-ico","lp.about.btn-register") }} FINMA
                                        <small>{{ getMyLanguage("lp-ico","lp.about.consult-website") }}</small>
                                    </span>
                                </a>
                                
                                <a href="https://so-fit.ch/recherche/" target="_blank" class="btn-link">
                                    <span>
                                        {{ getMyLanguage("lp-ico","lp.about.btn-register") }} SO-FIT
                                        <small>{{ getMyLanguage("lp-ico","lp.about.consult-website") }}</small>
                                    </span>
                                </a>
                                
                                <p class="obs"><small v-html='getMyLanguage("lp-ico","lp.about.obs")'></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- ENTITIES -->
        <section class="entities">
            <div class="container-fluid">
                <div class="row row-description">
                    <div class="col-10 col-sm-6 col-lg-4">
                        <img :src="`https://design.platform-web-app.pages.dev/home/dne-ecosystem-${flagSelected}.png`" alt="DNE Ecosystem">
                    </div>

                    <div class="col-12 col-lg-6 col-xl-5 col-description">
                        <h2 v-html='getMyLanguage("lp-ico","lp.entities.title")'></h2>

                        <div v-html='getMyLanguage("lp-ico","lp.entities.txt")'></div>
                    </div>
                </div>


                <!-- ENTITY > INTERNATIONAL -->
                <div class="row row-entity row-international row-reverse">
                    <div class="col-12 col-lg-4 col-txt">
                        <div class="entity">
                            <div class="entity-txt">
                                <h2><strong><small>DNE</small>International</strong></h2>
                                <div v-html='getMyLanguage("lp-ico","lp.entities.dne-international.txt")'></div>
                                <a href="https://dnetoken.com/pt" class="btn-secondary">{{ getMyLanguage("lp-ico","lp.entities.cta.website") }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 col-carousel">
                        <Carousel :autoplay="3000" :wrap-around="true">
                            <Slide v-for="(slider, slide) in slides.dneinternational" :key="slide">
                                <div class="carousel__item">
                                    <img :src='slider.img' alt="DNE Token">
                                    <div class="legend">{{ getMyLanguage("lp-ico","lp.entities.dne-international.legend." + slider.legend) }}</div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>


                <!-- ENTITY > MARKET -->
                <div class="row row-entity row-market">
                    <div class="col-12 col-lg-4 col-txt">
                        <div class="entity">
                            <div class="entity-txt">
                                <h2><strong><small>DNE</small>Market</strong></h2>
                                <div v-html='getMyLanguage("lp-ico","lp.entities.dne-market.txt")'></div>
                                <a href="https://dneecosystem.com/pt/market" class="btn-secondary">{{ getMyLanguage("lp-ico","lp.entities.cta.more") }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 col-carousel">
                        <Carousel :autoplay="3000" :wrap-around="true">
                            <Slide v-for="(slider, slide) in slides.dnemarket" :key="slide">
                                <div class="carousel__item">
                                    <img :src='slider.img' alt="DNE Market">
                                    <div class="legend">{{ getMyLanguage("lp-ico","lp.entities.dne-market.legend." + slider.legend) }}</div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>


                <!-- ENTITY / PAY -->
                <div class="row row-entity row-pay row-reverse">
                    <div class="col-12 col-lg-4 col-txt">
                        <div class="entity">
                            <div class="entity-txt">
                                <h2><strong><small>DNE</small>Pay</strong></h2>
                                <div v-html='getMyLanguage("lp-ico","lp.entities.dne-pay.txt")'></div>
                                <a href="https://dneecosystem.com/pt/pay" class="btn-secondary">{{ getMyLanguage("lp-ico","lp.entities.cta.more") }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 col-carousel">
                        <Carousel :autoplay="3000" :wrap-around="true">
                            <Slide v-for="(slider, slide) in slides.dnepay" :key="slide">
                                <div class="carousel__item">
                                    <img :src='slider.img' alt="DNE Pay">
                                    <div class="legend">{{ getMyLanguage("lp-ico","lp.entities.dne-pay.legend." + slider.legend) }}</div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>


                <!-- ENTITY / EXCHANGE -->
                <div class="row row-entity row-exchange">
                    <div class="col-12 col-lg-4 col-txt">
                        <div class="entity">
                            <div class="entity-txt">
                                <h2><strong><small>DNE</small>Exchange</strong></h2>
                                <div v-html='getMyLanguage("lp-ico","lp.entities.dne-exchange.txt")'></div>
                                <a href="https://dneecosystem.com/pt/exchange" class="btn-secondary">{{ getMyLanguage("lp-ico","lp.entities.cta.more") }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 col-carousel">
                        <Carousel :autoplay="3000" :wrap-around="true">
                            <Slide v-for="(slider, slide) in slides.dneexchange" :key="slide">
                                <div class="carousel__item">
                                    <img :src='slider.img' alt="DNE Exchange">
                                    <div class="legend">{{ getMyLanguage("lp-ico","lp.entities.dne-exchange.legend." + slider.legend) }}</div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>


        <!-- DNE TOKEN -->
        <!-- <section class="dne-token">
            <div class="container-fluid">
                <h2 class="title-secondary" v-html='getMyLanguage("lp-ico","lp.dne-token.phases.title")'></h2>

                <div class="row">
                    <div class="col-12 col-countdown">
                        <div class="float">
                            <div class="count">
                                <CountdownICO />
                            </div>

                            <a href="#formRegister" class="btn-primary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}</a>
                        </div>

                        <div class="float-mobile" :class="{install: showInstall != 'false'}">
                            <a href="#formRegister" class="btn-primary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}</a>
                        </div>
                    </div>

                    <div class="col-12 phases phases-ico">
                        <Phases />
                    </div>
                </div>
            </div>
        </section> -->


        <!-- REGISTER -->
        <section class="register" v-if="!registered">
            <div class="anc" id="formRegister"></div>
            <div class="container">
                <div class="row" v-if='showContent && !errorsPost.newRegister'>
                    <div class="col-12 col-lg-6">
                        <h2 class="title-secondary">{{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}</h2>
                        
                        <div class="box-confirm-email">
                            <div class="anchor" id="interested"></div>
                            <p class="subtitle">{{ getMyLanguage("register","register.title.interested.msg") }}</p>
                            
                            <div v-if="$store.state.phaseCurrent.phase_type == 'Public'" class="box-registration cta-register">
                                <div class="formBox">
                                    <p>{{ getMyLanguage("purchase-token","purchase-token.content.dne-token.emphasis") }}</p>

                                    <div class="btn-center">
                                        <div class="btn-primary btn-register" @click="openModalRegistration()">{{ getMyLanguage("login","login.register-now") }}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-else class="box-registration lp-registration" :class="{dropListOpened: (dropListOpened.languageCompany || dropListOpened.countryCompany || dropListOpened.ddiCompany || dropListOpened.languagePerson || dropListOpened.countryPerson || dropListOpened.ddiPerson)}">
                                <div class="formBox">
                                    
                                    <div class="invite-person">
                                        <div class="anc anc-company" id="!person"></div>

                                        <h3 v-if='flow.typeCode === "account_type_company"'>{{getMyLanguage("purchase-token","purchase-token.content.box-register.subtitle.partnerData")}}</h3>

                                        <!-- NAME -->
                                        <label class="formFullName">
                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.fullname")}}</span>
                                            <input v-model="newRegister.person.fullName" type="text" class="form" :class="{error: errorForm.personInvite.fullName}" @keydown="errorForm.personInvite.fullName = false" maxlength="80" autocomplete="off" >
                                        </label>
                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>


                                        <!-- EMAIL -->
                                        <label class="formYourEmail">
                                            <span class="ph">{{getMyLanguage("client-space","client-space.account.lbl.email")}}</span>
                                            <input v-model="newRegister.person.email" type="email" id="email" class="form formYourEmail" :class='{error: (errorForm.personInvite.email)}' @blur='regexEmail()' maxlength="60" autocomplete="off">
                                        </label>
                                        <p class="msg-validate-form msg-error anime" v-if='validateEmail.person.regex === false || errorForm.personInvite.email'>{{getMyLanguage("errors","errors.emailError")}}</p>


                                        <!-- LANGUAGE -->
                                        <div class="autocomplete formLanguage" :class="{opened: dropListOpened.languagePerson}">
                                            <div class="openAutocomplete" v-if="!listLanguagesStatus" @click="dropListLanguages(true)"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesStatus" v-on:click="dropListLanguages(false)"></div>

                                            <label>
                                                <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageFlag}/flags`" class="flag-selected" v-if="selectedLanguageFlag != false">
                                                <input type="text" id="languageID" class="form" :class="{selected: selectedLanguageFlag != false, error: errorForm.personInvite.languageId}" @focus="dropListLanguages(true)" v-on:keyup="filterListLanguage" @blur="dropListLanguages(false)" @keydown.esc="dropListLanguages(false)" :value="languagesList(selectedLanguage.code)" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                            </label>
                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.languageId'>{{getMyLanguage("errors","errors.language")}}</p>

                                            <ul id="drop-list" v-if="listLanguagesStatus">
                                                <li v-for="(itemList,index) in langsTranslated" :key="index" v-on:click="(newRegister.person.languageId = itemList.id),(selectedLanguage.code = itemList.code),(selectedLanguageFlag = itemList.flag), (dropListLanguages(false)), errorForm.personInvite.languageId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                    <a>{{ languagesList(itemList.code) }}</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- COUNTRY -->
                                        <div class="autocomplete formResidenceCountry" :class="{opened: dropListOpened.countryPerson}">
                                            <div class="openAutocomplete" v-if="!listCountryStatus" @click="dropListCountry(true)"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" v-on:click="dropListCountry(false)"></div>

                                            <label>
                                                <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")}}</span>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false">
                                                <input type="text" id="residenceCountry" class="form" :class="{selected: selectedCountryFlag != false, error: errorForm.personInvite.countryId}" @focus="dropListCountry(true)" @blur="dropListCountry(false)" @keydown.esc="dropListCountry(false)" v-on:keyup="filterListCountry" :value="countryTranslate(selectedCountry.IsoCode)" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")' autocomplete="off">
                                            </label>
                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>

                                            <ul id="drop-list" v-if="listCountryStatus">
                                                <li v-for="(itemList,index) in countriesTranslated" :key="index" v-on:click="(newRegister.person.countryId = itemList.Id), (newRegister.person.phoneCountryId = itemList.Id), (this.changeMask()), (selectedCountryFlag = itemList.Flag), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = itemList.PhoneMask), (selectedCountryDDI.ddi = itemList.DDI), (selectedCountryDDI.flag = itemList.Flag), (selectedCountry.IsoCode = itemList.IsoCode), (dropListCountry(false)), errorForm.personInvite.countryId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                    <a>{{ countryTranslate(itemList.IsoCode)}}</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- PHONE -->
                                        <div class="formPhone" :class='{opened: dropListOpened.ddiPerson, active: selectedCountryDDI.active, error: (errorsPost.newRegister === "account_activate_phone_already_exists" || errorForm.personInvite.phoneNumber)}'>
                                            <label>
                                                <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.phone")}}</span>
                                            </label>
                                            
                                            <div class="d-flex">
                                                <div class="select-ddi" v-if="selectedCountryDDI.countrySelected && selectedCountryDDI.changeMask">
                                                    <div class="open-select-ddi" @click="dropListDDI(true)"></div>
                                                    <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect" @click="dropListDDI(false)"></div>
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                                        </div>
                                                        <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                    </div>
                                                    <ul class="list-ddi" v-if="selectedCountryDDI.openSelect">
                                                        <div class="scroll">
                                                            <li v-for="(ddiList,index) in dataCountry" :key="index" @click='(newRegister.person.phoneCountryId = ddiList.Id), (selectedCountryDDI.phoneMask = ddiList.PhoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.DDI), (selectedCountryDDI.flag = ddiList.Flag), (dropListDDI(false)), (newRegister.person.phoneNumber = "")'>
                                                                <div class="flag">
                                                                    <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                                </div>
                                                                <span class="name-country">{{ddiList.Name}}</span>
                                                                <span class="ddi" >(+{{ddiList.DDI}})</span>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </div>

                                                <label v-if="selectedCountryDDI.changeMask">
                                                    <input v-model="validatePhone.person.val" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" @keydown="errorForm.personInvite.phoneNumber = false" @blur='regexPhone("person")' maxlength="25" autocomplete="off" >
                                                </label>
                                            </div>

                                            <div class="form load-phone" v-if="!selectedCountryDDI.changeMask"></div>
                                        </div>
                                        <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.person.regex === false || errorForm.personInvite.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                        <p class="msg-validate-form msg-error anime" v-if='errorsPost.newRegister === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                        

                                        <!-- ACCEPTS -->
                                        <div class="accepts">
                                            <div class="form-group-check">
                                                <input type="checkbox" id="acceptTerms" class="form-check" v-model="accept.terms">
                                                <label for="acceptTerms" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptTerms}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small></label>
                                                <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}</a>
                                            </div>
                                            
                                            <!-- <div class="form-group-check">
                                                <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                                <label for="acceptNotify" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptNotify}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small></label>
                                            </div> -->
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                                <label for="acceptGDPR" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptGdpr}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small></label>
                                            </div>
                                        </div>

                                        <!-- <div id="btnRegister" v-if="!accept.terms || !accept.gdpr" @click="validationFormInvite()" class="btn-primary btn-disabled">{{ getMyLanguage("register","register.btn-register") }}</div>
                                        <div id="btnRegister" v-if="accept.terms && accept.gdpr && !load && !errorsPost.general" @click="validationFormInvite()" class="btn-primary">{{ getMyLanguage("register","register.btn-register") }}</div> -->
                                        <div id="btnRegister" v-if="!accept.terms || !accept.gdpr" @click="validationFormInvite()" class="btn-primary btn-disabled">REGISTAR</div>
                                        <div id="btnRegister" v-if="accept.terms && accept.gdpr && !load && !errorsPost.general" @click="validationFormInvite()" class="btn-primary">REGISTAR</div>
                                        <div class="btn-primary load" v-if="load"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="box-error" v-if="errorsPost.newRegister != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","general-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsPost.newRegister}}
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- SUCCESS -->
        <section class="register-success" v-if="registered">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-10 col-lg-6">
                        <div class="box-success">
                            <h2 class="title-secondary">{{ getMyLanguage("lp-ico","success.title") }}</h2>
                            <p>{{ getMyLanguage("lp-ico","success.msg") }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL REGISTRATION -->
    <div class="modal modal-registration" v-if="modalRegistration == true">
        <div class="modal-cont">
            <div class="modal-close" @click="modalRegistration = false"></div>
            <div class="modal-iframe">
                <iframe :src="urlIframeRegistration" frameborder="0"></iframe>
            </div>
        </div>
    </div>


    <!-- ZOOM -->
    <div class="modal modal-zoom" v-if="zoom.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="zoom.modal = false"></div>

            <div class="modal-scroll">
                <div class="load load-200" v-if="zoom.load"></div>

                <img v-if="!zoom.load && zoom.entity == 'international'" src="~@/assets/images/content/ico/dne-international.jpg" alt="DNE International">
                <img v-if="!zoom.load && zoom.entity == 'exchange'" src="~@/assets/images/content/ico/dne-exchange.jpg" alt="DNE Exchange">
                <img v-if="!zoom.load && zoom.entity == 'pay'" src="~@/assets/images/content/ico/dne-pay.jpg" alt="DNE Pay">
                <img v-if="!zoom.load && zoom.entity == 'market'" src="~@/assets/images/content/ico/dne-market.jpg" alt="DNE Market">
            </div>
        </div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    // import CountdownICO from '@/components/CountdownICO.vue';
    // import Phases from '@/components/Phases.vue';

    import 'vue3-carousel/dist/carousel.css'
    import { defineComponent } from 'vue'
    import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import langsList from "@/services/listLanguages.js";
    import countriesList from "@/services/listCountries.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default defineComponent({
        components: {
            Header,
            // CountdownICO,
            // Phases,
            Carousel,
            Slide,
            Pagination,
            Navigation
        },

        data(){
            return{
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,

                showContent: false,
                load: false,
                PageEntity: "ecosystem",
                origin: "modal",
                showInstall: null,

                modalRegistration: false,
                urlIframeRegistration: "",

                slides: {
                    dneinternational: [
                        { img: "/lp-ico/dne-token-register.jpg", legend: "01"},
                        { img: "/lp-ico/dne-token-logo-coin.jpg", legend: "02"},
                        { img: "/lp-ico/dne-token-referral-plan.jpg", legend: "03"},
                        { img: "/lp-ico/dne-token-purchase.jpg", legend: "04"},
                        { img: "/lp-ico/dne-token-purchase-history.jpg", legend: "05"}
                    ],
                    dnemarket: [
                        { img: "/lp-ico/dne-market-home.jpg", legend: "01"},
                        { img: "/lp-ico/dne-market-search.jpg", legend: "02"},
                        { img: "/lp-ico/dne-market-minicart.jpg", legend: "03"},
                        { img: "/lp-ico/dne-market-dashboard.jpg", legend: "04"}
                    ],
                    dnepay: [
                        { img: "/lp-ico/dne-pay-p2p-world.jpg", legend: "01"},
                        { img: "/lp-ico/dne-pay-wallets.jpg", legend: "02"},
                        { img: "/lp-ico/dne-pay-p2p.jpg", legend: "03"},
                        { img: "/lp-ico/dne-pay-receive.jpg", legend: "04"},
                        { img: "/lp-ico/dne-pay-checkout.jpg", legend: "05"}
                    ],
                    dneexchange: [
                        { img: "/lp-ico/dne-exchange-register.jpg", legend: "01"},
                        { img: "/lp-ico/dne-exchange-mobile.jpg", legend: "02"},
                        { img: "/lp-ico/dne-exchange-desktop.jpg", legend: "03"},
                        { img: "/lp-ico/dne-exchange-kyc.jpg", legend: "04"},
                        { img: "/lp-ico/dne-exchange-deposit.jpg", legend: "05"}
                    ]
                },

                zoom: {
                    load: false,
                    modal: false,
                    entity: ""
                },

                errorsGet: {
                    dataInvite: false
                },
                
                errorsPost: {
                    newRegister: false,
                    country_pep: false,
                    general: false
                },

                validateEmail: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },
                
                validatePhone: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },

                flow: {
                    typeCode: "account_type_person",
                },

                dataCountry: [],
                dataLanguage: [],
                
                flagSelected: "en",
                showFlag: false,
                showFlagCompany: false,

                listLanguagesStatus: false,
                selectedLanguage: {},
                selectedLanguageFlag: false,
                
                listLanguagesCompanyStatus: false,
                selectedLanguageCompany: {},
                selectedLanguageCompanyFlag: false,

                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,
                
                listCountryCompanyStatus: false,
                selectedCountryCompany: {},
                selectedCountryFlagCompany: false,
                
                selectedCountryDDICompany: {
                    changeMask: true,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                dropListOpened: {
                    languageCompany: false,
                    countryCompany: false,
                    ddiCompany: false,

                    languagePerson: false,
                    countryPerson: false,
                    ddiPerson: false
                },

                newRegister: {
                    person: {
                        pep: false,
                        taxNumber: ""
                    },
                    company: {}

                    // person: {
                    //     pep: false,
                    //     fullName: "",
                    //     email: "",
                    //     countryId: "",
                    //     languageId: "",
                    //     phoneCountryId: "",
                    //     phoneNumber: "",
                    //     taxNumber: ""
                    // },
                    // company: {
                    //     pep: false,
                    //     fullName: "",
                    //     email: "",
                    //     countryId: "",
                    //     languageId: "",
                    //     phoneCountryId: "",
                    //     phoneNumber: "",
                    //     taxNumber: ""
                    // }
                },
                
                utm: {
                    referrerId: "",
                    source: "",
                    campaign: ""
                },


                urlPost: "",

                registered: false,

                errorForm: {
                    personInvite: {},
                    acceptsInvite: {
                        acceptTerms: false,
                        acceptNotify: false,
                        acceptGdpr: false
                    },

                    person: false,
                    accepts: false
                },

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },
            }
        },    
        
        
        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            if ( urlParams.size > 0 ){
                localStorage.setItem("referrerID", urlParams.get('utm_content'))
                localStorage.setItem("utmSource", urlParams.get('utm_source'))
                localStorage.setItem("utmCampaign", urlParams.get('utm_campaign'))
            }
            
            setTimeout(() => {
                if (localStorage.getItem("referrerID")){
                    this.utm.referrerId = localStorage.getItem("referrerID")
                    this.utm.source = localStorage.getItem("utmSource")
                    this.utm.campaign = localStorage.getItem("utmCampaign")
                }

                this.showInstall = sessionStorage.getItem("showInstallApp")
            }, 500);

            
            // LANGUAGE
            await apiPlatform.get('/api/v1/platform/language/dne_ecosystem')
            .then(response => {
                this.dataLanguage = response.data
                
                setTimeout(() => {
                    this.orderLangs()
                }, 100);
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })


            // COUNTRY
            await apiPlatform.get('api/v1/platform/country')
            .then(response => {
                this.dataCountry = response.data
                
                setTimeout(() => {
                    this.ordercountries()
                }, 100);
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })


            setTimeout(() => {
                this.showContent = true
            }, 500);
        },


        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("lp-private-sale","title") + " | DNE Ecosystem";
                document.getElementsByTagName('meta')["description"].content = ''
            },

            openModalRegistration(){
                if ( this.utm.referrerId ){
                    this.urlIframeRegistration = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register-modal/?utm_content=${this.utm.referrerId}&utm_source=${this.utm.source}&utm_campaign=${this.utm.campaign}`

                    this.modalRegistration = true
                } else {
                    this.urlIframeRegistration = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register-modal/`
                    
                    this.modalRegistration = true
                }
            },

            zoomImg(value){
                this.zoom.load = true
                this.zoom.modal = true
                this.zoom.entity = value

                setTimeout(() => {
                    this.zoom.load = false
                }, 1500);
            },


            // LANGUAGES
            languagesList(code){
                return langsList(code, this.$store.state.SelectLanguage.code)
            },

            orderLangs() {
                this.langsTranslated = [...this.dataLanguage];

                this.langsTranslated.forEach(lang => {
                    lang.translatedName = this.languagesList(lang.code);
                });

                this.langsTranslated.sort((a, b) => {
                    return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
                });
            },
            
            
            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];

                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            regexEmail(){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.newRegister.person.email)) {
                    this.validateEmail.person.regex = true
                } else{
                    this.validateEmail.person.regex = false
                }
            },

            // checkEmail(){
            //     if ( this.newRegister.person.checkEmail === this.newRegister.company.email ){
            //         this.newRegister.company.status = true
            //     } else{
            //         this.newRegister.company.status = false
            //     }
            // },

            regexPhone(){
                const regex = /^\+[1-9]\d{8,14}$/;

                let phoneE164Person = this.selectedCountryDDI.ddi + this.validatePhone.person.val
                phoneE164Person = "+" + phoneE164Person.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Person)) {
                    this.validatePhone.person.regex = true
                } else{
                    this.validatePhone.person.regex = false
                }
            },

            changeFlagLanguage(value){
                this.showFlag = false
                this.newRegister.person.languageId = value

                this.showFlag = true
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true
                }, 1000);
                
                setTimeout(() => {
                    document.getElementById('phoneNumber').focus()
                }, 1050);
            },
            
            dropListCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryPerson = true
                        document.getElementById('residenceCountry').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryPerson = false
                }
            },
            
            dropListLanguages(status){
                setTimeout(() => {
                    this.listLanguagesStatus = status
                }, 250)

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languagePerson = true
                        document.getElementById('languageID').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languagePerson = false
                }
            },
            
            
            dropListDDI(status){
                setTimeout(() => {
                    this.selectedCountryDDI.openSelect = status
                }, 250);

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.ddiPerson = true
                    }, 10);
                } else {
                    this.dropListOpened.ddiPerson = false
                }
            },


            filterListLanguage() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("languageID");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },
            
            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("residenceCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },


            validationFormInvite(){
                
                // PERSON NAME
                if ( this.newRegister.person.fullName ){
                    var personName = this.newRegister.person.fullName
                    personName = personName.trim().split(' ').length
                    
                    if ( personName < 2 ){
                        this.errorForm.personInvite.fullName = true
                    } else {
                        this.errorForm.personInvite.fullName = false
                    }
                } else {
                    this.errorForm.personInvite.fullName = true
                }


                // EMAIL
                // if ( this.newRegister.person.email === "" || !this.validateEmail.person.regex || !this.validateEmail.person.status ){
                if ( !this.newRegister.person.email || !this.validateEmail.person.regex ){
                    this.errorForm.personInvite.email = true
                } else {
                    this.errorForm.personInvite.email = false
                }


                // LANGUAGE
                if ( this.newRegister.person.languageId === "" ){
                    this.errorForm.personInvite.languageId = true
                } else {
                    this.errorForm.personInvite.languageId = false
                }


                // COUNTRY
                if ( this.newRegister.person.countryId === "" ){
                    this.errorForm.personInvite.countryId = true
                } else {
                    this.errorForm.personInvite.countryId = false
                }


                // PHONE
                if ( this.validatePhone.person.val === "" ){
                    this.errorForm.personInvite.phoneNumber = true
                } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
                    this.errorForm.personInvite.phoneNumber = true
                } else {
                    this.errorForm.personInvite.phoneNumber = false
                }


                // ACCEPTS
                if ( !this.accept.terms ){
                    this.errorForm.acceptsInvite.acceptTerms = true
                } else{
                    this.errorForm.acceptsInvite.acceptTerms = false
                }
                
                // if ( !this.accept.notify ){
                //     this.errorForm.acceptsInvite.acceptNotify = true
                // } else{
                //     this.errorForm.acceptsInvite.acceptNotify = false
                // }
                
                if ( !this.accept.gdpr ){
                    this.errorForm.acceptsInvite.acceptGdpr = true
                } else{
                    this.errorForm.acceptsInvite.acceptGdpr = false
                }

                if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptGdpr ){
                    this.errorForm.accepts = true
                } else {
                    this.errorForm.accepts = false
                }


                // CKECK VALIDATION COMPLIANCE
                if ( this.errorForm.personInvite.fullName || this.errorForm.personInvite.email || this.errorForm.personInvite.languageId || this.errorForm.personInvite.countryId || this.errorForm.personInvite.phoneNumber ){
                    window.location.href = "#!person"
                    this.errorForm.person = true
                } else {
                    this.errorForm.person = false
                }


                // LAST CKECK
                if ( !this.errorForm.accepts && !this.errorForm.person ){
                    this.register()
                }
            },

            // register(){
            //     window.alert("foi")
            // }

            
            register(){
                this.load = true

                let dataPhonePerson = this.validatePhone.person.val
                this.newRegister.person.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')
                
                if ( this.utm.referrerId ){
                    this.urlPost = `/api/v2/platform/account/lead?referrer_account_id=${this.utm.referrerId}`
                } else {
                    this.urlPost = "/api/v2/platform/account/lead"
                }

                apiPlatform.post(this.urlPost, this.newRegister)
                .then(response => {
                    console.log(response)
                    this.registered = true

                    // this.registerId = response.data.id;
                })
                .catch(error => {
                    console.log(error.response.data)

                    this.errorsPost.newRegister = error.response.status
                    console.log(this.errorsPost.newRegister)

                    // if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
                    //     this.errorsPost.newRegister = error.response.data
                    // } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
                    //     this.errorsPost.country_pep = true
                    // } else{
                    //     this.errorsPost.general = error.response.status

                    //     setTimeout(() => {
                    //         this.errorsPost.general = false
                    //     }, 4000);
                    // }

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected()
                    this.orderLangs()
                    this.ordercountries()
                }
            }
        },

        created () {
            document.title = "ICO - PUBLIC SALE | DNE Token";
            document.getElementsByTagName('meta')["description"].content = ''
        },
    })
</script>

<style src="./custom-ico.scss" lang="scss" scoped />
<style src="./custom-invite.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_modal.scss';

    .ico-public-sale{
        h2{
            @media (max-width: 992px){
                font-size: 26px;
                line-height: 26px;    
            }

            @media (min-width: 992px){
                font-size: 40px;
                line-height: 40px;    
            }

            small{
                display: block;
                color: $color-secondary;
            }
        }
    }

    .dne-token{
        h2.title-secondary{
            color: $color-text-tertiary;
        
            @media (max-width: 576px){
                font-size: 32px;
                line-height: 36px;
            }
            
            @media (min-width: 576px){
                font-size: 42px;
                line-height: 46px;
            }
        
            &:after{
                display: none;
            }
        
            strong{
                display: block;
                color: $color-secondary;
            }
        }
    }


    .row-entity{
        .col-carousel{
            section.carousel{
                .carousel__prev, .carousel__next{
                    background: #fff;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    
                    .carousel__icon{
                        fill: $color-primary;
                    }
                }
    
                .carousel__prev{
                    @media (min-width: 576px){
                        left: 25px;
                    }
                }
                
                .carousel__next{
                    @media (min-width: 576px){
                        right: 25px;
                    }
                }
    
                .carousel__pagination{
                    position: absolute;
                    bottom: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 0;
                    margin: 0;
    
                    .carousel__pagination-item{
                        display: flex;
                        align-items: center;
                        padding: 0;
                        height: 20px;
    
                        .carousel__pagination-button{
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            transition: .3s;
                            background: #aaa;
        
                            &.carousel__pagination-button--active{
                                width: 20px;
                                height: 20px;
                                background: $color-secondary;
                            }
                        }
                    }
                }


                .carousel__slide--active{
                    .carousel__item{
                        .legend{
                            @media (max-width: 576px){
                                animation: showLegendMobile .6s alternate;
                            }
                            
                            @media (min-width: 576px){
                                animation: showLegendDesktop .6s alternate;
                            }
                        }
                    }

                    @keyframes showLegendMobile {
                        0% {opacity: 0; bottom: 0;}
                        50% {opacity: 0; bottom: 0;}
                        100% {opacity: 1; bottom: .5rem;}
                    }
                    
                    @keyframes showLegendDesktop {
                        0% {opacity: 0; bottom: .5rem;}
                        50% {opacity: 0; bottom: .5rem;}
                        100% {opacity: 1; bottom: 1.2rem;}
                    }
                }
            }
        }

        &.row-reverse{
            .carousel__viewport{
                @media (max-width: 992px){
                    border-radius: 0 0 30px 8px;
                }
                
                @media (min-width: 992px){
                    border-radius: 40px 0 0 12px;
                }
            }
        }

        &:not(&.row-reverse){
            .carousel__viewport{
                @media (max-width: 992px){
                    border-radius: 0 0 30px 8px;
                }
                
                @media (min-width: 992px){
                    border-radius: 0 12px 40px 0;
                }
            }
        }
    }


    // BOX REGISTRATION
    .box-registration{
        &.cta-register{
            max-width: 350px;
            margin: 0 auto;
            
            p{
                text-align: center;
            }

            .btn-center{
                display: flex;
                justify-content: center;

                .btn-primary{
                    width: auto;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    // MODAL REGISTRATION
    .modal-registration{
        .modal-cont{
            max-width: 500px;
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>