<template>
    <div class="newTK">
        <!-- <div class="redirect">
            <div class="load" v-if="load"></div>
        </div> -->
    </div>
</template>

<script>
    export default {
        data () {
            return {
                load: true,
                newtk: "",
                destiny: ""
            }
        },

        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            this.newtk = urlParams.get('tk')
            this.destiny = urlParams.get('destiny');

            localStorage.removeItem('accessToken');
            localStorage.setItem('accessToken',this.newtk);
            
            setTimeout(() => {
                if ( localStorage.getItem("accessToken") === this.newtk ){
                    // this.$router.push({ name: 'ClientSpaceWallets', params: { lang: this.$route.params.lang } })
                    window.location.href = `../${this.destiny}`
                } else {
                    // this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    window.location.href = `../account/login?destiny=${this.destiny}`
                }
            }, 2000);  
        },

        created () {
            document.title = "DNE Token | Consent";
        }
    }
</script>

<style scoped>
    .load{width: 160px; height: 160px; margin: 30px;}
</style>

<style>
    .newTK + footer{display: none !important;}
</style>