<template>
    <div class="distribution-chart" v-if="showContent">
        <div class="row row-distribution">
            <div class="col-12 col-lg-6">
                <h2 class="title-secondary" data-aos="zoom-out" data-aos-offset="-200">
                    {{getMyLanguage("purchase-token","distribution.title")}}
                    <small>{{getMyLanguage("purchase-token","distribution.subTitle.global")}}</small>
                </h2>

                <img :src="`/distribution-global-${$store.state.SelectLanguage.code}.png`" data-aos="fade-up" data-aos-offset="-200">
            </div>
            
            <div class="col-12 col-lg-6">
                <h2 class="title-secondary" data-aos="zoom-out" data-aos-offset="-200">
                    {{getMyLanguage("purchase-token","distribution.title")}}
                    <small>{{getMyLanguage("purchase-token","distribution.subTitle.circulation")}}</small>
                </h2>

                <img :src="`/distribution-circulation-${$store.state.SelectLanguage.code}.png`" data-aos="fade-up" data-aos-offset="-200">
            </div>
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        data(){
            return {
                showContent: false,
            }
        },

        async mounted(){
            setTimeout(() => {
                this.showContent = true
            }, 100);
        },

        methods: {            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        }
    }
</script>

<style src="@/assets/scss/_distribution.scss" lang="scss" scoped />