<template>
    <section class="referral-plan">
        <div class="load" v-if="load"></div>

        <div class="container" v-if="errosGet != false && !load">
            <div class="box-pending">
                <!-- <h3 class="title-secondary">{{ getMyLanguage("box-error","title-error") }}</h3> -->
                <p>{{ getMyLanguage("box-error","msg-not-enabled") }}</p>
            </div>
        </div>

        <div class="container" v-if="!errosGet && !load">
            <h2 class="title-secondary">{{ getMyLanguage("ReferralPlan","referral-plan.title") }}</h2>

            <!-- RESUME -->
            <div class="row-resume">
                <div class="resume active">
                    <div class="cont">
                        <h3>{{ getMyLanguage("ReferralPlan","active.subtitle") }}</h3>

                        <div class="value">
                            <h4><span>{{dataReferral.Done.value.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h4>
                        </div>

                        <p>{{ getMyLanguage("ReferralPlan","active.obs") }}</p>
                    </div>
                </div>
            
                <div class="resume inactive">
                    <div class="cont">
                        <h3>{{ getMyLanguage("ReferralPlan","inactive.subtitle") }}</h3>

                        <div class="value">
                            <h4><span>{{dataReferral.Future.value.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h4>
                        </div>

                        <p>{{ getMyLanguage("ReferralPlan","inactive.obs") }}</p>
                    </div>
                </div>
            </div>


            <!-- SALES -->
            <div class="sales">
                <div class="item" :class="{concluded: item.activated, pending: !item.activated}" v-for="(item, index) in dataReferral.depths" :key="index">
                    <div class="level">{{ getMyLanguage("ReferralPlan","level." + item.type) }}</div>
                    
                    <div class="meta">
                        <span :class="{concluded: item.accounts.done >= item.accounts.required, pending: item.accounts.done < item.accounts.required}">
                            <strong>{{item.accounts.done}}<small>/</small>{{item.accounts.required}}</strong> {{ getMyLanguage("ReferralPlan","meta.sales") }}
                        </span>
                        
                        <span :class="{concluded: item.companies.done >= item.companies.required, pending: item.companies.done < item.companies.required}">
                            <strong>{{item.companies.done}}<small>/</small>{{item.companies.required}}</strong> {{ getMyLanguage("ReferralPlan","meta.registers") }}
                        </span>
                    </div>

                    <div class="value">
                        <h3><span>{{item.total.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h3>
                    </div>

                    <div class="obs" v-if="(item.accounts.done < item.accounts.required) || (item.companies.done < item.companies.required)">
                        <span v-if="item.accounts.done < item.accounts.required">
                            {{ getMyLanguage("ReferralPlan","meta.obs.pending") }}: {{item.accounts.required - item.accounts.done}} {{ getMyLanguage("ReferralPlan","meta.sales") }}
                        </span>

                        <span v-if="item.companies.done < item.companies.required">
                            {{ getMyLanguage("ReferralPlan","meta.obs.pending") }}: {{item.companies.required - item.companies.done}} {{ getMyLanguage("ReferralPlan","meta.registers") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js';
    // import { handleError } from '@/services/errorHandler.js'

    // import axios from 'axios';
    import moment from "moment";

    export default {
        data() {
            return {
                flagSelected: "en",
                load: true,
                dataReferral: [],

                errosGet: false
            };
        },

        async mounted() {
            await apiInternational.get("/api/v2/sales/agent/sale_depths")
            .then((response) => {
                this.dataReferral = response.data;
                console.log(this.dataReferral)
                this.load = false
            })
            .catch((error) => {
                this.load = false
                this.errosGet = error
                console.log(error)
            });
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            loginStatus() {
                if (
                    localStorage.getItem("accessToken") === undefined ||
                    localStorage.getItem("accessToken") === null
                ) {
                    window.location.href = "/";
                }
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    };
</script>

<style src="@/assets/scss/_referral-plan.scss" lang="scss" scoped />