<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <section class="compliance">
            <div class="confirm-compliance">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <h2 class="title-secondary">
                                {{ getMyLanguage("compliance", "compliance.title-success") }}
                            </h2>

                            <div class="box-confirmation">
                                <div class="icon-confirm-compliance">
                                    <img src="~@/assets/images/icons/register-success.svg">
                                </div>

                                <div class="msg msg-success" v-html='getMyLanguage("compliance", "compliance.msg-success")'></div>

                                <div class="msg msg-attention">
                                    <a :href="`/${flagSelected}/client-space/purchase`" class="btn-primary">
                                        {{getMyLanguage("compliance","compliance.cta-success")}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header
        },

        data(){
            return{
                flagSelected: "en",
            }
        },
        
        async mounted(){
            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.consent");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.compliance");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-compliance-success.scss" lang="scss" scoped />