<template>
    <div class="home beneficial-page">
        <div id="dne"></div>
        <Header />

        <section class="beneficial">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataCompliance != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>

                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    
                    <!-- <div class="cod-error">
                        Cód.: {{errorsGet.dataCompliance}}
                    </div> -->
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataCompliance">
                
                <div class="box-beneficial">
                    <h2 class="title-secondary">Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange</h2>

                    <!-- ALERT KYC PARTNER -->
                    <div class="box-waiting" v-if="!partners.kycPartner">
                        <h3 class="title-secondary">KYC</h3>
                        <p><strong>Não há sócio(s) com KYC verificado.</strong></p>
                    </div>
                    
                    <!-- FORM CONTROLLING -->
                    <div v-if="auth2fa.status == 'form' && partners.kycPartner">
                        <div class="box-registration">
                            <div class="formBox">
                                <p>(for operating legal entities and partnerships that are contracting partner as well as analogously for operating legal entities and partnerships that are beneficial owners)</p>
                                <div class="partner">
                                    <div>
                                        <h3 class="subtitle">Contracting partner:</h3>
                                        <p>DNE International Sagl</p>
                                        <p>CHE-409.547.100 / Switzerland</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="anc" id="errorMemberType"></div>

                            <div class="formBox">
                                <h3>Pursuant to the anti-money laundering legislation, the contracting partner(s) hereby declare(s) (tick the appropriate box):</h3>

                                <div class="form-group-radio" :class="{error: errorsForm.company_member_type}">
                                    <input type="radio" id="box1" v-model="compliance.company_member_type" value="beneficial_owner" name="companyMemberType" class="form-radio" @click='compliance.company_member_type = "beneficial_owner", validateError()' :checked='compliance.company_member_type == "beneficial_owner"' >
                                    <label for="box1" class="form-radio-label">
                                        <span>the person(s) listed below is / are holding 25 % or more of the contracting partner’s shares (capital shares or voting rights); or</span>
                                    </label>

                                    <input type="radio" id="box2" v-model="compliance.company_member_type" value="partner" name="companyMemberType" class="form-radio" @click='compliance.company_member_type = "partner", validateError()' :checked='compliance.company_member_type == "partner"' >
                                    <label for="box2" class="form-radio-label">
                                        <span>if there are no capital shares or voting rights of 25 % or more, that the following person(s) listed below is / are controlling the contracting partner in other ways; or</span>
                                    </label>
                                    
                                    <input type="radio" id="box3" v-model="compliance.company_member_type" value="manager" name="companyMemberType" class="form-radio" @click='compliance.company_member_type = "manager", validateError()' :checked='compliance.company_member_type == "manager"' >
                                    <label for="box3" class="form-radio-label last">
                                        <span>in case no person(s) exist(s) who exercise(s) control over the contracting partner in a different capacity, the contracting partner hereby declares that the person(s) listed below is / are the managing director(s).</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="anc" id="error"></div>

                            <div class="formBox">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="formName">
                                            <span>First name(s), last name(s) / entity:</span>
                                            <!-- <input type="text" class="form" :class="{error: errorsForm.full_name}" v-model="compliance.full_name" v-on:input="signatureName()" @keyup='validateError("fullName")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'> -->
                                            <input type="text" class="form" :class="{error: errorsForm.full_name}" v-model="compliance.full_name" @keyup='validateError("fullName")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>
                                            <small class="error" v-if="errorsForm.full_name">{{getMyLanguage("compliance","compliance.error.comparative")}} {{dataCompliance.full_name}}</small>
                                        </label>
                                    </div>

                                    <div class="col-12">
                                        <label class="formAddress last">
                                            <span>Actual address of domicile / registered office (incl. country):</span>
                                            <input type="text" class="form" :class="{error: errorsForm.full_address}" v-model="compliance.full_address" @keyup='validateError("fullAddress")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullAddress")'>
                                            <small class="error" v-if="errorsForm.full_address">{{getMyLanguage("compliance","compliance.error.comparative")}} {{dataCompliance.full_address}}</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3>Fiduciary holding of assets</h3>
                                <p>Is a third person the beneficial owner of the assets booked under the above relationship?</p>
                                <div class="form-group-radio">
                                    <input type="radio" id="boxNo" v-model="compliance.fiduciary_holding_assets" name="fiduciaryHolding" class="form-radio" @click='compliance.fiduciary_holding_assets = false' :value="false" :checked='compliance.fiduciary_holding_assets === false' >
                                    <label for="boxNo" class="form-radio-label">
                                        <span>No.</span>
                                    </label>

                                    <input type="radio" id="boxYes" v-model="compliance.fiduciary_holding_assets" name="fiduciaryHolding" class="form-radio" @click='compliance.fiduciary_holding_assets = true' :value="true" :checked='compliance.fiduciary_holding_assets === true' >
                                    <label for="boxYes" class="form-radio-label last">
                                        <span>Yes. &rarr; The relevant information regarding the beneficial owner has to be obtained by filling in a separate form A.</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3 class="subtitle">The contracting partner(s) hereby undertake(s) to automatically inform the bank of any changes.</h3>

                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Date:</span>
                                            <span class="form formDateToday">{{dateTime()}}</span>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Signature(s):</span>

                                            <div class="digital-signature" :class="{error: errorsForm.signature}">
                                                <vueSignature
                                                ref="signature"
                                                :sigOption="digitalSignature.option"
                                                :w="'100%'"
                                                :h="'120px'"
                                                ></vueSignature>

                                                <span @click="clear" class="btn-clear"></span>
                                                
                                                <label>
                                                    <small class="error" v-if="errorsForm.signature">
                                                        {{getMyLanguage("compliance","compliance.error.signature-empty")}}
                                                    </small>
                                                </label>
                                            </div>

                                            <span class="form formSignature" v-html="partners.full_name" v-if="partners.qtd == 1"></span>
                                        </label>
                                    </div>
                                </div>

                                <p class="alert">It is a criminal offence to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery).</p>
                            </div>
                        </div>

                        <div class="bt-registration">
                            <div class="btn-primary" @click='validateForm()'>
                                {{ getMyLanguage("compliance", "compliance.modal2fa.title") }}
                            </div>
                        </div>
                    </div>


                    <!-- AUTH 2FA -->
                    <AuthKYC @statusChange="handleStatusChange" :dataSource="auth2fa.kyc" v-if="auth2fa.status == 'auth'" />

                    <!-- SUCCESS 2FA -->
                    <div v-if="auth2fa.status == 'success'">
                        <div class="box-registration box-status-2fa success-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.success.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.success.msg") }}</p>
                                <div class="redirecting">{{ getMyLanguage("compliance","compliance.2fa.success.redirecting") }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- RETRY 2FA -->
                    <div v-if="auth2fa.status == 'retry'">
                        <div class="box-registration box-status-2fa retry-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.retry.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.retry.msg") }}</p>

                                <div class="bts">
                                    <div class="btn-primary" @click="auth2fa.status = 'auth'">{{ getMyLanguage("compliance","compliance.2fa.retry.cta") }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- PENDENTE 2FA -->
                    <div v-if="auth2fa.status == 'pending'">
                        <div class="box-registration box-status-2fa pending-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.pending.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.pending.msg") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </section>
    </div>



    <!-- MODAL PARTNERS -->
    <div class="modal modal-partners" v-if="modalPartners">
        <div class="modal-cont">
            <div class="modal-close" @click="modalPartners = false"></div>
            
            <div class="modal-scroll">
                <h3 class="title-secondary">{{ getMyLanguage('compliance',"compliance.2fa.modal-partners.title") }}</h3>

                <div :class="['partner', {inactive: !listPartner.kyc}]" @click="listPartner.kyc ? selectPartner(listPartner.account_id) : null" v-for="(listPartner, index) in dataCompliance.partners" :key="index">
                    <strong>{{listPartner.full_name}}</strong>
                    <span v-if="listPartner.kyc">KYC: {{ getMyLanguage('compliance',"compliance.2fa.modal-partners.verified") }}</span>
                    <span v-else>KYC: {{ getMyLanguage('compliance',"compliance.2fa.modal-partners.pending") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    // import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';

    import vueSignature from "vue-signature";
    import AuthKYC from "@/components/AuthKYC.vue"

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header,
            vueSignature,
            AuthKYC
        },

        data(){
            return{
                showContent: false,
                flagSelected: "en",
                load: false,

                compliance: {
                    company_member_type: "beneficial_owner",
                    full_name: "",
                    full_address: "",
                    fiduciary_holding_assets: false,
                    signature: ""
                },

                digitalSignature: {
                    option: {
                        penColor: "rgb(0, 0, 0)",
                        backgroundColor: "rgb(255,255,255)",
                    },
                },

                errorsForm: {
                    company_member_type: false,
                    full_name: false,
                    full_address: false,
                    signature: false
                },

                errorsGet: {
                    dataCompliance: false
                },

                errorsPost: {
                    signature: false
                },
                
                dataCompliance: {},

                partners: {
                    qtd: "",
                    kycPartner: "",
                    full_name: ""
                },

                modalPartners: false,

                auth2fa: {
                    status: "form",
                    kyc: {
                        signature: "",
                        account_id: "",
                        check_type: "action",
                        behalf_of_account_id: ""
                    },
                }
            }
        },
        
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
                // return moment(value).locale(this.flagSelected).format('ll');
            },

            async loadCompliance(){
                console.log(this.$store.state.profile)
                await apiPlatform.get(`/api/v2/platform/compliance/ubo/company/${this.$store.state.profile.account_id}`)
                .then(response => {
                    this.dataCompliance = response.data
                    console.log(this.dataCompliance)

                    this.compliance.company_member_type = this.dataCompliance.company_member_type
                    this.compliance.fiduciary_holding_assets = this.dataCompliance.fiduciary_holding_assets

                    this.partners.qtd = this.dataCompliance.partners.length
                    this.partners.kycPartner = this.dataCompliance.partners.filter(kyc => kyc.kyc == true).length
                    this.signatureName()
                    console.log(this.partners.kycPartner)

                    this.showContent = true
                })
                .catch(error => {
                    // this.errorsGet.dataCompliance = error.response.data
                    this.errorsGet.dataCompliance = "general"
                    this.showContent = true

                    handleError(error, this.flagSelected, this.$store.state.profile.account_type);
                })
            },

            signatureName(){
                const nameSignature = this.dataCompliance.partners[0].full_name;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.partners.full_name = nameSignatureFinished;
            },

            validateError(){
                // if ( this.compliance.full_name.toLowerCase() == this.dataCompliance.full_name.toLowerCase() ){
                //     this.errorsForm.full_name = false
                // }

                if ( this.compliance.company_member_type != "none" ){
                    this.errorsForm.company_member_type = false
                }
                
                if ( this.compliance.full_name == this.dataCompliance.full_name ){
                    this.errorsForm.full_name = false
                }

                if ( this.dataCompliance.full_address != null ){
                    if ( this.compliance.full_address == this.dataCompliance.full_address ){
                        this.errorsForm.full_address = false
                    }
                }

            },

            validateForm(){
                this.isEmpty()
                this.saveDigitalSignature()

                if ( this.compliance.company_member_type == 'none' ){
                    this.errorsForm.company_member_type = true
                    const errorElement = document.getElementById("errorMemberType");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.company_member_type = false
                }

                if ( this.dataCompliance.full_address != null ){
                    if ( this.compliance.full_name != this.dataCompliance.full_name || this.compliance.full_name == "" ){
                        this.errorsForm.full_name = true
    
                        const errorElement = document.getElementById("error");
                        errorElement.scrollIntoView();
                    } else {
                        this.errorsForm.full_name = false
                    }
                } else {
                    if ( this.compliance.full_name != this.dataCompliance.full_name || this.compliance.full_name == "" || this.compliance.full_name == null ){
                        this.errorsForm.full_name = true
    
                        const errorElement = document.getElementById("error");
                        errorElement.scrollIntoView();
                    } else {
                        this.errorsForm.full_name = false
                    }
                }
                

                if ( this.compliance.full_address != this.dataCompliance.full_address || this.compliance.full_address == "" || this.compliance.full_address == null ){
                    this.errorsForm.full_address = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.full_address = false
                }


                if ( !this.errorsForm.company_member_type && !this.errorsForm.full_name && !this.errorsForm.full_address && !this.errorsForm.signature ){
                    this.auth2fa.kyc.signature = this.compliance.signature
                    this.auth2fa.kyc.behalf_of_account_id = this.$store.state.profile.account_id
                    
                    if ( this.partners.qtd == 1 ){
                        this.auth2fa.kyc.account_id = this.dataCompliance.partners[0].account_id
                        this.auth2fa.status = "auth"
                    } else {
                        this.modalPartners = true
                    }
                }
            },

            selectPartner(value){
                this.modalPartners = false
                this.auth2fa.kyc.account_id = value
                this.auth2fa.status = "auth"
                this.compliance.company_member_account_id = value
            },

            clear() {
                this.$refs.signature.clear()
            },

            isEmpty() {
                return this.$refs.signature.isEmpty()
            },

            saveDigitalSignature() {
                if (this.isEmpty()) {
                    this.errorsForm.signature = true;
                } else {
                    const svgSignature = this.$refs.signature.save("image/svg+xml");
                    console.log(svgSignature);

                    this.errorsForm.signature = false;
                    this.compliance.signature = svgSignature.replace('data:image/svg+xml;base64,', '');
                }

                
                // var _this = this;
                // var svgSignature = _this.$refs.signature.save("image/svg+xml");
                // console.log(svgSignature);
                
                // if ( svgSignature == "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNDkzIDEyMCIgd2lkdGg9IjQ5MyIgaGVpZ2h0PSIxMjAiPjwvc3ZnPg==" ){
                //     this.errorsForm.signature = true
                // } else {
                //     this.errorsForm.signature = false
                //     this.compliance.signature = svgSignature.replace('data:image/svg+xml;base64,', '')
                // }

            },

            handleStatusChange(newStatus) {
                this.auth2fa.status = newStatus;
            },

            saveBO(){
                apiPlatform.post(`/api/v2/platform/compliance/ubo/company/${this.$store.state.profile.account_id}`, this.compliance)
                .then(response => {
                    console.log(response)
                    this.$toast.success(this.getMyLanguage('compliance','compliance.title-success'))

                    setTimeout(() => {
                        window.location.href = '/' + this.flagSelected + '/client-space/purchase'
                    }, 5000);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                })


                // apiInternational.post('api/v1/international/compliance/beneficial_owner', this.signature)
                // .then(response => {
                //     console.log(response)
                //     this.$toast.success(this.getMyLanguage('compliance','compliance.title-success'))

                //     setTimeout(() => {
                //         window.location.href = '/' + this.flagSelected + '/client-space/purchase'
                //     }, 5000);
                // })
                // .catch(error => {
                //     console.log(error)
                //     this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                // })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            },

            "$store.state.profile": {
                handler() {
                    setTimeout(() => {
                        this.loadCompliance();
                    }, 1000);
                }
            },

            'auth2fa.status'(newStatus) {
                if (newStatus == 'success') {
                    this.saveBO();
                }
            }
        },

        created () {
            // document.title = this.getMyLanguage("seo","title.account.compliance");
            document.title = "Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange";
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-ubo.scss" lang="scss" scoped />