<template>
    <div class="home kyc-page">
        <div id="dne"></div>
        <Header />

        <section class="kyc">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataKYC != false">
                <div class="box-error">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error", errorsGet.dataKYC) }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataKYC}}
                    </div>
                </div>
            </div>

            <!-- IF SUMSUB -->
            <div class="container" v-if="showContent && KycSumSub && !errorsGet.dataKYC">                
                <h2 class="title-secondary">KYC</h2>

                <h3 class="alert alert-initial" v-if="alert.initial">
                    {{getMyLanguage("kyc","kyc.alert")}}
                </h3>

                <h3 class="alert alert-pending" v-if="alert.pending">
                    {{getMyLanguage("kyc","kyc.alert.pending")}}
                </h3>
                
                <div id="sumsub-websdk-container"></div>
            </div>

            <!-- IF SHUFTI -->
            <div class="container" v-if="showContent && kycShuftiPro && !errorsGet.dataKYC">
                <h2 class="title-secondary">KYC</h2>

                <h3 class="alert alert-initial" v-if="alert.initial">
                    {{getMyLanguage("kyc","kyc.alert")}}
                </h3>

                <h3 class="alert alert-pending" v-if="alert.pending">
                    {{getMyLanguage("kyc","kyc.alert.pending")}}
                </h3>

                <!-- <iframe src="https://app.Shuftipro.com/process/kyc/c4KpX8kI1D9kHi3L8Ww2mfAOxTyqRdMvhPFAyEYiodmBIEdWOI8OPmkuG1O1Je8G" width="100%" height="500px" id="Shuftipro-iframe" allow="camera" frameborder="0"></iframe> -->
                <iframe :src='dataKYC.accessToken' allow="camera" id="Shuftipro-iframe" frameborder="0" class="ShuftiproIframe"></iframe>
            </div>

        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import snsWebSdk from '@sumsub/websdk';

    import apiInternational from '@/config/apiInternational.js'
  
    export default {
        components: {
            Header
        },

        data(){
            return{
                showContent: false,
                KycSumSub: false,
                kycShuftiPro: false,

                dataKYC: {},

                alert: {
                    initial: true,
                    pending: false
                },

                errorsGet: {
                    dataKYC: false
                },

                flagSelected: "en",
            }
        },        
        
        async mounted(){
            await apiInternational.get('/api/v1/platform/account/international/kyc')
            .then(response => {
                this.dataKYC = response.data
                this.modalKYC = true

                if ( this.dataKYC.provider === "ShuftiPro" ){
                    this.kycShuftiPro = true
                } else{
                    this.KycSumSub = true
                }
                
                this.showContent = true
            })
            .catch(error => {
                this.errorsGet.dataKYC = error.response.status
                this.errorsGet.dataKYC = true
                this.showContent = true
            })
            
            this.launchWebSdk(this.dataKYC.accessToken, this.dataKYC.applicantEmail, this.dataKYC.applicantPhoneNumber, this.dataKYC.language)
        },


        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.kyc");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            redirect(){
                window.alert("redirect")
            },

            launchWebSdk(accessToken, applicantEmail, applicantPhone, applicantLanguage) {
                let snsWebSdkInstance = snsWebSdk
                    .init(accessToken, () => this.getNewAccessToken())
                    .withConf({
                        lang: applicantLanguage,
                        email: applicantEmail,
                        phone: applicantPhone,
                    })
                    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                    .on('idCheck.onStepCompleted', (payload) => {
                        console.log('onStepCompleted', payload)
                    })
                    .on('idCheck.onError', (error) => {
                        console.log('onError', error)
                    })
                    .onMessage((type, payload) => {
                        console.log("onMessage", type, payload);
                    })
                    .on('idCheck.onApplicantStatusChanged', (status) => {
                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "RETRY" ){
                            this.alert.initial = false
                            this.alert.pending = true
                        }

                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "FINAL" ){
                            window.location.href = `/${this.flagSelected}/account/pending`
                        }

                        if ( status.reviewResult.reviewAnswer === "GREEN" ){
                            window.location.href = `/${this.flagSelected}/account/compliance`
                        }
                    })

                    .build();

                snsWebSdkInstance.launch('#sumsub-websdk-container')
            },

            getNewAccessToken() {
                return Promise.resolve(this.dataKYC.accessToken)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.kyc");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="@/views/ClientSpace/custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-kyc.scss" lang="scss" scoped />