<template>
    <div class="invoice" style="position: relative; padding-bottom: 50px; width: 700px">
        <vue3-simple-html2pdf
            ref="vue3SimpleHtml2pdf"
            :options="pdfOptions"
            :filename="exportFilename"
            >

            <table border="0" cellspacing="20" cellpadding="0" style="width: 100%;" v-if="showContent && !errorsGet">
                <tr valign="middle">
                    <td style="width: 50%">
                        <img src="~@/assets/images/client-space/dne-international.png" alt="DNE International" style="width: 230px; height: 49px; margin-bottom: 30px;">

                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">{{dataInvoice.issuer.name}}</strong></p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.issuer.address}}</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.issuer.email}}</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">VAT: {{dataInvoice.issuer.taxNumber}}</p>
                    </td>

                    <td style="width: 50%">
                        <div style="border: 1px solid #999; padding: 16px; border-radius: 12px;">
                            <table style="width: 100%; border-bottom: 5px solid #ccc; padding-bottom: 10px; margin-bottom: 15px;">
                                <tr>
                                    <td>
                                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">Agreement Number</strong></p>
                                    </td>
                                    <td style="text-align: right;">
                                        <h3 style="font-family: Arial; font-size: 20px; line-height: 20px; color: $color-primary; margin: 0;">{{dataInvoice.details.number}}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">Date</p></td>
                                    <td style="text-align: right;"><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">{{ dateTime(dataInvoice.date) }}</strong></p></td>
                                </tr>
                            </table>

                            <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.recipient.name}}</p>
                            <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.recipient.address}}</p>
                            <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">VAT: {{dataInvoice.recipient.taxNumber}}</p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td></td>
                </tr>
                
                <tr>
                    <td style="width: 50%">
                        <!-- <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">The exchange/purchase of coins or cryptocurrencies or tokens is exempt from VAT or indirect taxes in Switzerland, however, the profit derived from the sale of the asset or its exit from the e-wallet will be subject to the payment of capital gains at the applicable rate.</strong></p> -->
                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">Transaction vat exempt; Capital gains may be subject to declaration and taxes; You should consult your tax advisor and/or your country regulations.</strong></p>
                    </td>
                    <td style="width: 50%">
                        <div style="border: 1px solid #999; padding: 16px; border-radius: 12px;">

                            <table style="width: 100%; border-bottom: 5px solid #ccc; padding-bottom: 10px; margin-bottom: 15px;">
                                <tr>
                                    <td>
                                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">{{dataInvoice.details.detail}}</strong></p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">Total in EUR</p>
                                    </td>
                                    <td style="text-align: right;">
                                        <h3 style="font-family: Arial; font-size: 20px; line-height: 20px; color: $color-primary; margin: 0;">{{dataInvoice.details.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h3>
                                    </td>
                                </tr>
                            </table>

                            <table style="width: 100%">
                                <tr>
                                    <td><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;"><strong style="color: #1f3561">Resume</strong></p></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">Original price</p></td>
                                    <td style="text-align: right;"><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.details.originalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p></td>
                                </tr>
                                <tr>
                                    <td><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">Discount ({{dataInvoice.details.discount}}%)</p></td>
                                    <td style="text-align: right;"><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.details.discountValue.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p></td>
                                </tr>
                                <tr>
                                    <td><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">VAT (0%)</p></td>
                                    <td style="text-align: right;"><p style="font-family: Arial; font-size: 14px; line-height: 24px; margin: 0;">{{dataInvoice.details.taxFee.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <div style="width: 100%; margin: 50px 0 20px 0;">
                            <div style="display: flex;">
                                <div style="width: 95px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Description</div>
                                <div style="width: 95px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Quantity lots</div>
                                <div style="width: 105px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Quantity units</div>
                                <div style="width: 80px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Unit cost</div>
                                <div style="width: 105px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Gross amount</div>
                                <div style="width: 90px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Discount</div>
                                <div style="width: 90px; font-family: Arial; font-weight: bold; font-size: 13px; color: #1f3561; text-align: center; line-height: 20px; padding: 5px 0; border: 1px solid #ddd;">Net amount</div>
                            </div>

                            <div style="display: flex; flex-wrap: wrap;"  v-for="(listInvoice,index) in dataInvoice.invoiceItems" :key="index">
                                <div style="width: 95px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.description}}</div>
                                <div style="width: 95px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.quantityLots}}</div>
                                <div style="width: 105px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.quantityUnits}}</div>
                                <div style="width: 80px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.unitAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</div>
                                <div style="width: 105px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.grossAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</div>
                                <div style="width: 90px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.discountAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</div>
                                <div style="width: 90px; font-family: Arial; font-size: 13px; text-align: center; line-height: 30px; border: 1px solid #ddd;">{{listInvoice.netAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</div>
                                <div style="width: 100%; font-family: Arial; font-size: 13px; text-align: center; text-align: center; line-height: 18px; margin: 10px 0" v-if="listInvoice.blockchainTx != null"><strong style="display: block; color: #1f3561">DNE Token registered in Stellar Blockchain Nr.:</strong>{{listInvoice.blockchainTx}}</div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <p style="background: #eee; padding: 15px; border-radius: 12px; font-family: Arial; font-size: 13px; text-align: center; line-height: 18px; margin: 0;">The information provided on this document does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the company website's content as such. DNE International SAGL does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.</p>
                        <div style="text-align: right; border-top: 1px solid #ccc; margin-top: 20px; padding-top: 5px">1/2</div>
                    </td>
                </tr>
                <div class="html2pdf__page-break"></div>

                <tr>
                    <td colspan="2">
                        <h2 style="font-size: 30px; color: $color-primary; text-align: center; margin-bottom: 30px">Terms and conditions of this agreement:</h2>
                        <h3 style="font-size: 20px; line-height: 20px; color: $color-primary; margin: 0 0 10px 0;">Considerations:</h3>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- Lots - The DNE TOKEN is a digital unit. One lot of DNE TOKEN consists of 180 token units, at a price of €0,50 per unit.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- Private sale – During the “Private Sale” phase there will be available for sale the total amount of 60,000 Lots of 180 units, which can be extended to up to 100,000 lots depending on demand, at a price of €90 per lot with a 40% discount corresponding to €54 per lot, in return for keeping the lots in stake for 18 months.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- Only DNE users can buy DNE Tokens, under the following rights and conditions:</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- Private sale starts from June 10, 2022, which will last 90 days (ninety days), the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 40% discount, with the obligation to keep the respective tokens for a period of 18 months, counting from the date of signature of this agreement of the period of the private sale referred to herein. Wallets will be made available at the start of the ICO.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- This discount cannot be accumulated with others.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- The minimum purchase order for each DNE User is 5.400 tokens, corresponding to 30 (thirty) lots and a cumulative maximum of 250 (two hundred and fifty) lots, corresponding to 45.000 tokens for each purchasing entity or person.</p>
                        
                        <h3 style="font-size: 20px; line-height: 20px; color: $color-primary; margin: 30px 0 10px 0;">Use, Transfer or send:</h3>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- During the first stage, you are not allowed to close the Plan or Use, transfer or send DNE TOKEN assets maintained in the same prior to its expiration. In the event that you submit a request to Use, transfer or send your DNE TOKEN bought with the above mentioned conditions, being only one or all of the token batches, and / or request to close your wallet Account prior the expiration of this Plan, you agree that we shall return the entire portfolio of the wallet referring to the purchase made at this stage, minus the 40% discount, plus a 25% penalty for breach of contract.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- This transaction vat exempt; Capital gains may be subject to declaration and taxes; You should consult your tax advisor and/or your country regulations.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">-  The information provided on this document does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the company website's content as such. DNE International SAGL does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">- This agreement was registered on Blockchain.</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">Questions and inquiries regarding the Terms and Conditions of Purchase should be sent to support@dnetoken.com</p>
                        <p style="font-family: Arial; font-size: 14px; line-height: 22px; margin: 0;">In the resolution of doubts arising from the fulfillment of this Agreement, the provisions in force in this type of agreement will be applied and the parties elect as competent the jurisdiction of the Courts of Lugano, Switzerland, with express waiver of any other.</p>

                        <div style="text-align: right; border-top: 1px solid #ccc; margin-top: 20px; padding-top: 5px">2/2</div>
                    </td>
                </tr>
                
            </table>

            <!-- <div class="html2pdf__page-break"></div>

            <div class="html2pdf__page-number">2/2</div> -->


            <!-- <img src="base64 image or url"> -->

            <!-- You can loop to display page number as you want -->
            <!-- <div class="html2pdf__page-number">1</div> -->

            <!-- Break page pdf -->
            <!-- <div class="html2pdf__page-break"></div> -->

        </vue3-simple-html2pdf>

        <div @click="generateReport()" class="download-pdf">PDF</div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import moment from 'moment';
    import apiInternational from '@/config/apiInternational.js'

    const html2pdf = require('html2pdf.js')
    // const jsPDF = require('jspdf')

    export default {
        props: {
            index: {
                type: Number,
                default: 1,
            },
            filename: {
                type: String,
                default: 'Agreement_DNE_International.pdf',
            },
            options:{
                default: {
                    margin: 15,
                    image: {
                        type: 'jpeg',
                        quality: 1,
                    },
                    html2canvas: { scale: 3 },
                }
            }
        },

        data(){
            return{
                dataInvoice: [],
                showContent: false,
                errorsGet: ""
            }
        }, 

        async mounted(){
            await apiInternational.get(`/api/v1/international/sales/invoice/${this.$route.params.registerId}`)
            .then(response => {
                this.dataInvoice = response.data
                this.errorsGet = false
            })
            .catch(error => {
                this.errorsGet = true
                console.log(error)
            })

            this.showContent = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            generateReport () {
                // this.$refs.vue3SimpleHtml2pdf.download()

                const el = document.getElementById(`Vue3SimpleHtml2pdf${this.index}`)
                if (!el) {
                    return
                }
                html2pdf().from(el).set(this.options).save(this.filename)
            }
        },
        
        created () {
            document.title = "DNE Token | Invoice";
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
            document.head.querySelector('meta[name=viewport]').content = ''
        },
    }
</script>

<style>
    .invoice + footer + .cookie-consent{
        display: none !important;
    }
    .download-pdf{
        position: fixed;
        left: 10px;
        bottom: 10px;
        line-height: 40px;
        height: 40px;
        font-size: 18px;
        padding: 0 40px;
        background: #4aae57;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        cursor: pointer;
        border: none
    }

    .download-pdf:before{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background: url('~@/assets/images/icons/download.svg') no-repeat center;
        background-size: auto 100%;
    }
</style>