<template>
    <div>
        <header :class="{develop: VUE_APP_DEVELOP == 'develop'}">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4 col-xl-2 col-logo">
                        <a :href='"/" + flagSelected + "#dne"' class="logo">
                            <img src="@/assets/images/header/logo-dne.svg" alt="DNE Digital New Economy">
                        </a>
                    </div>

                    <div class="col-8 col-xl-10">
                        <div class="row-master">
                            <div class="col-menu">
                                <MenuInternational />
                            </div>

                            <div class="col-lang-login">
                                <Login />
                                <SelectLanguage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>


<script>
    import MenuInternational from '@/components/MenuInternational.vue';
    import SelectLanguage from '@/components/SelectLanguage.vue';
    import Login from '@/components/Login.vue';

    export default {
        components: {
            MenuInternational,
            SelectLanguage,
            Login
        },

        data (){
            return {
                flagSelected: "en",
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />