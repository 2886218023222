<template>
    <div class="load" v-if="load"></div>

    <div class="resume-client-countdown" v-if="!load">
        <div class="resume-client-countdown-cont">
            <!-- <div v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'">
                <h6 v-if="dataPhaseCurrent.phase_name == 'Private Sale'">{{ getMyLanguage("purchase-token","phase-private.nameGroupPhasePrivate") }}</h6>
                <h6 v-else>{{ getMyLanguage("purchase-token","phase-private.nameGroupPhasePublic") }}</h6>

                <p>
                    {{dataPhaseCurrent.product_quantity}} DNE Token > {{dataPhaseCurrent.purchase_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}
                    <span>(-{{dataPhaseCurrent.phase_discount}}%)</span><span v-if="dataPhaseCurrent.phase_bonus > 0"> + {{dataPhaseCurrent.phase_bonus}}% bonus</span>
                </p>
                <Countdown v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'" />
            </div> -->

            <div class="dne-token-cta-purchase">
                <img src="@/assets/images/client-space/dne-coin.png" alt="DNE Token Coin">
                
                <h2 class="title-primary">
                    DNE <span>T</span>oken
                </h2>
            </div>
        </div>


        <div class="purchase-now">
            <a :href="`/${flagSelected}/client-space/purchase`" class="btn-secondary">
                <span>{{ getMyLanguage("client-space", "client-space.countdown-cta") }}</span>
            </a>
        </div>
    </div>
</template>


<script>
    // import Countdown from '@/components/Countdown.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js'

    import moment from 'moment';

    export default{
        components: {
            // Countdown
        },

        data: function () {
            return {
                flagSelected: "en",
                dataPhaseCurrent: [],
                load: true
            }
        },

        async mounted(){
            const resumePhaseCurrent = await apiInternational.get('/api/v2/ico/phase/current')
            this.dataPhaseCurrent = resumePhaseCurrent.data

            setTimeout(() => {
                this.load = false
            }, 500);
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                return moment(value).locale("en").format('ll');
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style src="@/assets/scss/_clientSpaceCountdown.scss" lang="scss" scoped />
