<template>
    <section class="dne-faq">
        <div class="anc" id="faq"></div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <h2 class="title-secondary" data-aos="fade-right" data-aos-offset="-200">{{ getMyLanguage("faq", "faq.title") }}</h2>
                    <p data-aos="fade-right" data-aos-offset="-200">{{ getMyLanguage("faq", "faq.subTitle") }}</p>
                    <a :href="`/${flagSelected}/support`" class="btn-primary">{{ getMyLanguage("faq", "faq.anotherQuestion") }}</a>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="faq">
                        <div
                            class="question" data-aos-offset="-200"
                            v-for="item in faq"
                            :key="item.id"
                            :class="{active: item.active}"
                            @click="toggleAnswer(item.id)">

                            <h6>{{item.question}}</h6>
                            <p v-show="item.active">{{item.answer}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        data(){
            return{
                flagSelected: "en",

                faq: [
                    {
                        id: "1",
                        question: this.getMyLanguage("faq", "faq.titleQuestion01"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion01"),
                        isActive: false
                    },
                    {
                        id: "2",
                        question: this.getMyLanguage("faq", "faq.titleQuestion02"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion02"),
                        isActive: false
                    },
                    {
                        id: "3",
                        question: this.getMyLanguage("faq", "faq.titleQuestion03"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion03"),
                        isActive: false
                    },
                    {
                        id: "4",
                        question: this.getMyLanguage("faq", "faq.titleQuestion04"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion04"),
                        isActive: false
                    },
                    {
                        id: "5",
                        question: this.getMyLanguage("faq", "faq.titleQuestion05"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion05"),
                        isActive: false
                    },
                    {
                        id: "6",
                        question: this.getMyLanguage("faq", "faq.titleQuestion06"),
                        answer: this.getMyLanguage("faq", "faq.answerQuestion06"),
                        isActive: false
                    }
                ]
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            toggleAnswer(id) {
                const faqItem = this.faq.find(item => item.id === id);
                if (faqItem) {
                    faqItem.isActive = !faqItem.isActive;
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';

    .dne-faq{
        @include paddingSection;
        @include bgGrey;
        background-size: cover;
        background-attachment: fixed;

        .title-secondary{
            display: inline-block;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }

        .btn-primary{
            display: inline-flex;
            text-transform: uppercase;

            @media (max-width: 992px){
                margin: 20px 0 40px 0;
            }

            @media (min-width: 992px){
                margin-top: 30px;
            }
        }
        
        .faq{
            .question{
                border-top: 1px solid #bbb;
                transition: .8s;

                h6{
                    font-family: $font-primary-semibold;
                    color: $color-text-primary;
                    line-height: 20px;
                    padding-right: 20px;
                    padding: 1.4rem .7rem;
                    margin: 0;
                    position: relative;
                    cursor: pointer;

                    &:after{
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 1.4rem;
                        right: .2rem;
                        background: url('~@/assets/images/icons/arrow-accordion.svg') no-repeat center;
                        background-size: 12px auto;
                        transition: .8s;
                    }

                    @media (max-width: 576px){
                        font-size: 13px;
                    }

                    @media (min-width: 576px){
                        font-size: 16px;
                        cursor: pointer;
                    }
                }

                p{
                    margin: 0;
                    font-size: 85%;
                    padding: 0 .7rem 1.4rem .7rem;
                }

                &.active{
                    background: rgba($color: $color-text-tertiary, $alpha: 0.5);
                    border-color: rgba($color: #bbb, $alpha: 0) !important;
                    border-radius: 12px;

                     & + .question {
                        border-color: rgba($color: #bbb, $alpha: 0);
                    }

                    h6{
                        padding: 1.4rem 20px 1rem .7rem;

                        &:after{
                            transform: rotate(180deg);
                        }
                    }
                }

                &:last-child{
                    border-bottom: 1px solid #bbb;
                }
            }
        }
    }
</style>