<template>
    <div class="shufti">
        <div id="dne"></div>
        
        <section class="shufti-accepted">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <p>{{getMyLanguage("kyc","kyc.shufti-accepted")}}</p>
                            <a :href="`/${flagSelected}/account/compliance/`" class="btn-primary" target="_parent">
                                {{getMyLanguage("kyc","kyc.shufti-accepted.btn")}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="shufti-declined">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <p>{{getMyLanguage("kyc","kyc.shufti-declined")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            
        },

        data(){
            return{
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.kyc");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.kyc");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="@/views/ClientSpace/custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-shufti-success.scss" lang="scss" scoped />

<style>
    .shufti + footer{display: none !important;}
</style>