<template>
    <div class="home">
        <div id="dne"></div>
        <header>
            <div class="container">
                <div class="row">
                    <div class="col-6 col-lg-2 col-logo">
                        <a :href="`/${flagSelected}#dne`" class="logo">
                            <img src="@/assets/images/header/logo-dne.svg" alt="DNE Digital New Economy" class="anime">
                        </a>
                    </div>
                </div>
            </div>
        </header>

        <div class="redirect">
            <div class="load" v-if="load"></div>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        data () {
            return {
                flagSelected: "en",
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                load: true,
                newtk: "",
                destiny: ""
            }
        },

        async mounted(){
            this.showContent = true
            this.load = true
            this.newtk = localStorage.getItem('accessToken')

            window.location.href = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/consent/ask?scope=3fa85f64-5717-4562-b3fc-2c963f66afa6&tk=${this.newtk}&destiny=client-space`
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.consent");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.consent");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style scoped>
    .load{width: 160px; height: 160px;}
</style>