<template>
    <section class="install-app" v-if="showContent">
        <div class="btn-install">
            <div class="description">
                <div class="close" @click="closeInstallApp()"></div>
                <img src="https://app.dneecosystem.com/icon-app-ecosystem.png" alt="DNE Ecosystem App" class="icon">

                <div class="title">
                    <h4>DNE App</h4>
                    <p v-if="deviceMobile.ios">{{ getMyLanguage("install-app","app-store") }}</p>
                    <p v-if="deviceMobile.android">{{ getMyLanguage("install-app","google-play") }}</p>
                </div>
            </div>
                    
            <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank" v-if="deviceMobile.ios" class="install">{{ getMyLanguage("install-app","install") }}</a>
            <!-- <a href="https://testflight.apple.com/join/noFcnPvI" target="_blank" v-if="deviceMobile.ios" class="install">{{ getMyLanguage("install-app","install") }}</a> -->
            <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank" v-if="deviceMobile.android" class="install">{{ getMyLanguage("install-app","install") }}</a>
        </div>
    </section>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        data(){
            return{
                flagSelected: "en",
                showContent: false,

                deviceMobile: {
                    android: "",
                    ios: ""
                }
            }
        },

        async mounted(){
            
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            closeInstallApp(){
                this.$emit('close-install-app', false);
                
                this.showContent = false
                sessionStorage.setItem("showInstallApp",false)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
        },

        created() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // DEVICE ANDROID
            if (/android/i.test(userAgent) && !sessionStorage.getItem("showInstallApp")) {
                this.deviceMobile.android = true
                this.deviceMobile.ios = false
                this.showContent = true
            }

            // DEVICE iOS
            if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) && !sessionStorage.getItem("showInstallApp")) {
                this.deviceMobile.ios = true
                this.deviceMobile.android = false
                this.showContent = true
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
    }
</script>

<style src="@/assets/scss/_installApp.scss" lang="scss" scoped />