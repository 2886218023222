<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <DneTokenPurchase />

        <!-- DIGITAL NEW ECONOMY -->
        <section class="digital-new-economy">
            <div class="anc" id="concept"></div>
            <div class="container">
                <div class="row digital-new-economy-cont">
                    <div class="col-12 col-xl-10">
                        <h2 data-aos="fade-left" data-aos-offset="-200">
                            D<strong>igital</strong> N<strong>ew</strong> <em>E<strong>conomy</strong></em>
                        </h2>

                        <p v-html='getMyLanguage("purchase-token","purchase-token.content.digital-new-economy")' data-aos="fade-right" data-aos-offset="-200"></p>

                        <!-- <div class="btn"> -->
                            <!-- <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}#ecosystem`" class="btn-primary" target="_blank">{{ getMyLanguage("purchase-token","purchase-token.content.digital-new-economy.know-more") }}</a> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </section>


        <!-- ROADMAP -->
        <section class="roadmap">
            <div class="anc" id="roadmap"></div>
            <div class="container">
                <h2 class="title-secondary" data-aos="zoom-out" data-aos-offset="-200">Roadmap</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="roadmap-scroll">
                            <div class="roadmap-cont">
                            <div class="roadmap-item concluded" data-aos="fade-left" data-aos-offset="-200">
                                <h5>Private Sale</h5>
                                <p>&bull; Onboarding (kyc)</p>
                                <p>&bull; Payments</p>
                                <p>&bull; Staking of Tokens</p>
                                <p>&bull; Backoffice 1.0</p>

                                <div class="date">
                                    <span>
                                        {{ getMyLanguage("purchase-token", "roadmap.col01.date") }}
                                    </span>
                                </div>
                            </div>

                            <div class="roadmap-item" data-aos="fade-left" data-aos-offset="-200" data-aos-delay="150">
                                <h5>ICO / Pay</h5>
                                <p>&bull; Vouchers</p>
                                <p>&bull; Territorial Expansion</p>
                                <p>&bull; Commissioning</p>
                                <p>&bull; Customer Support</p>
                                <p>&bull; Payments/Transfers</p>
                                <p>&bull; Conversion to Fiat</p>

                                <div class="date">
                                    <span>
                                        {{ getMyLanguage("purchase-token", "roadmap.col02.date") }}
                                    </span>
                                </div>
                                <br>

                                <h5>Exchange </h5>
                                <p>&bull; Wallets</p>
                                <p>&bull; Trading</p>
                                <p>&bull; Staking</p>
                                <p>&bull; Deposits/Withdrawals</p>
                                <p>&bull; Defi/Ntf</p>
                            </div>

                            <div class="roadmap-item" data-aos="fade-left" data-aos-offset="-200" data-aos-delay="300">
                                <h5>Marketplace</h5>
                                <p>&bull; Marketing Tools</p>
                                <p>&bull; Custom Stores</p>
                                <p>&bull; Campaigns</p>
                                <p>&bull; WorldWide Shipping</p>
                                <p>&bull; Transactions</p>
                                <p>&bull; Physical Store/POS</p>
                                <p>&bull; Front Store Integration</p>

                                <div class="date">
                                    <span>
                                        {{ getMyLanguage("purchase-token", "roadmap.col03.date") }}
                                    </span>
                                </div>
                            </div>

                            <div class="roadmap-item" data-aos="fade-left" data-aos-offset="-200" data-aos-delay="450">
                                <!-- <h5>{{ getMyLanguage("dne-platform","home.products.bank.title") }}</h5> -->
                                <h5>Bank</h5>
                                <p>&bull; Digital Accounting</p>
                                <p>&bull; Bank Integration</p>
                                <p>&bull; Financial Solutions</p>
                                <p>&bull; Card Issue</p>

                                <div class="date">
                                    <span>
                                        {{ getMyLanguage("purchase-token", "roadmap.col04.date") }}
                                    </span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="roadmap-obs">
                            {{getMyLanguage("purchase-token","roadmap.obs")}}
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- DISTRIBUTION -->
        <section class="distribution">
            <div class="anc" id="distribution"></div>
            
            <div class="container">
                <DistributionChart />
            </div>
        </section>


        <!-- DOWNLOAD APP -->
        <section class="download-app">
            <div class="anc" id="download-app"></div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <figure class="download-app-figure">
                            <img src="@/assets/images/home/dne_app.png" alt="DNE App" data-aos="fade-left" data-aos-offset="-200" data-aos-duration="2000">
                        </figure>
                    </div>

                    <div class="col-12 col-lg-6 download-app-cont skew-right no-skew-mobile">
                        <h2 data-aos="fade-right" data-aos-offset="-200">
                            DNE <span>A</span>pp
                        </h2>
                        
                        <div v-html="getMyLanguage('dne-platform','home.content.app.content')" data-aos="fade-right" data-aos-offset="-200"></div>

                        <div class="bts-download">
                            <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                <img src="@/assets/images/home/app-dne-download-android.svg" alt="Google Play">
                            </a>

                            <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                <img src="@/assets/images/home/app-dne-download-ios.svg" alt="App Store">
                            </a>
                            <!-- <a href="https://testflight.apple.com/join/noFcnPvI" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                <img src="@/assets/images/home/app-dne-download-ios.svg" alt="App Store">
                            </a> -->
                        </div>
                    </div>

                </div>
            </div>
        </section>
        

        <Team />

        <Faq />

        <LegalPolicies />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import DneTokenPurchase from '@/components/DneTokenPurchase.vue'
    import DistributionChart from '@/components/DistributionChart.vue';
    import Team from '@/components/Team.vue';
    import Faq from '@/components/Faq.vue';
    import LegalPolicies from '@/components/LegalPolicies.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            DneTokenPurchase,
            DistributionChart,
            Team,
            Faq,
            LegalPolicies
        },

        data () {
            return {
                dataLang: getMyContentLanguage,
                logged: false,
                // flagSelected: "en",
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL
            }
        },

        async mounted(){
            this.logged = true

            this.videoHome()
        },

        methods: {
            // languageSelected(){
            //     this.flagSelected = this.$store.state.SelectLanguage.code
            // },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            videoHome(){
            const video = document.getElementById("dneVideo");
            video.playbackRate = 0.75;

            const playButton = document.getElementById("playbutton");

            playButton.addEventListener("click", handlePlayButton, false);
            playVideo();

            async function playVideo() {
                try {
                    await video.play();
                    playButton.className = "playing";
                } catch(err) {
                    playButton.className = "";
                }
            }

            function handlePlayButton() {
                if (video.paused) {
                    playVideo();
                } else {
                    video.pause();
                    playButton.className = "";
                }
            }
            },
            
            // goTop(){
            //    $('html, body').animate({scrollTop : 0},800);
            //    return false;
            // },
        },

        // watch: {
        //     '$store.state.SelectLanguage.code': {
        //         immediate: true,
        //         handler() {
        //             this.languageSelected();
        //         }
        //     }
        // },
        
        created () {
            document.title = this.getMyLanguage("seo","title.home");
            document.head.querySelector('meta[name=description]').content = this.getMyLanguage("seo","title.description.home")
        },
    }
</script>

<style src="./custom-home.scss" lang="scss" scoped />