<template>
    <div class="home beneficial-page">
        <div id="dne"></div>
        <Header />

        <section class="beneficial">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataCompliance != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>

                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataCompliance}}
                    </div>
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataCompliance">
                
                <div class="box-beneficial">
                    <h2 class="title-secondary">Declaration of identity of the beneficial owner</h2>
                    
                    <!-- FORM BENEFICIAL -->
                    <div v-if="auth2fa.status == 'form'">
                        <div class="box-registration">
                            <div class="formBox">
                                <div class="partner">
                                    <div>
                                        <h3 class="subtitle">Contracting partner:</h3>
                                        <p>DNE International Sagl</p>
                                        <p>CHE-409.547.100 / Switzerland</p>
                                    </div>
                                </div>
                                <p>In accordance with Article 4 of the Anti-Money Laundering Act, the contracting partner hereby declares that the person(s) listed below is / are the beneficial owner(s) of the assets deposited under the above relationship. If the contracting partner is the beneficial owner of the assets, the contracting partner’s details must be set out below:</p>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="anc" id="error"></div>

                            <div class="formBox">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="formName">
                                            <span>Complete name / entity:</span>
                                            
                                            <input type="text" class="form"
                                            :class="{error: errorsForm.full_name}"
                                            v-model="compliance.full_name"
                                            
                                            @keyup='validateError("fullName")'
                                            :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>

                                            <small class="error" v-if="errorsForm.full_name">
                                                {{getMyLanguage("compliance","compliance.error.comparative")}} {{dataCompliance.full_name}}
                                            </small>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label class="formPersonDOB">
                                            <span>Date of birth:</span>

                                            <input type="date" class="form"
                                            :class="{error: errorsForm.dob}"
                                            v-model="compliance.dob"
                                            @keyup='validateError("dob")'>
                                            
                                            <small class="error" v-if="errorsForm.dob">
                                                {{getMyLanguage("compliance","compliance.error.comparative")}} {{dateTime(dataCompliance.dob)}}
                                            </small>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete formIssuerCountry">
                                            <label :class="{required: errorsForm.country_id}">
                                                <span>Nationality:</span>

                                                <div class="openAutocomplete" v-if="!listCountryStatus" @click="listCountry(true)"></div>
                                                <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" @click="listCountry(false)"></div>

                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false && selectedCountryFlag != null">
                                                
                                                <input type="text" id="issuerCountry" class="form"
                                                :class="{selected: selectedCountryFlag != false}"
                                                @keyup="filterListCountry"
                                                @blur="listCountry(false), validateError"
                                                :value="countryTranslate(selectedCountry.IsoCode)"
                                                :placeholder='getMyLanguage("compliance","compliance.lbl.selectCountry")'>

                                                <ul id="drop-list" v-if="listCountryStatus">
                                                    <li v-for="(itemList,index) in countriesTranslated" :key="index" @click="compliance.country_id = itemList.Id, selectedCountry.IsoCode = itemList.IsoCode, selectedCountryFlag = itemList.Flag">
                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                        <a>{{ countryTranslate(itemList.IsoCode) }}</a>
                                                    </li>
                                                </ul>

                                                <small class="error" v-if="errorsForm.country_id">
                                                    {{getMyLanguage("compliance","compliance.error.comparative")}} {{ dataCountry.filter(country => country.Id == dataCompliance.country_id)[0].Name }}
                                                </small>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <label class="formAddress last">
                                            <span>Actual address of domicile / registered office (incl. country):</span>
                                            
                                            <input type="text" class="form"
                                            :class="{error: errorsForm.full_address}"
                                            v-model="compliance.full_address"
                                            @keyup='validateError("fullAddress")'
                                            :placeholder='getMyLanguage("compliance","compliance.placeholder.fullAddress")'>
                                            
                                            <small class="error" v-if="errorsForm.full_address">
                                                {{getMyLanguage("compliance","compliance.error.comparative")}} {{dataCompliance.full_address}}
                                            </small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3 class="subtitle">The contracting partner hereby undertakes to automatically inform the bank of any changes.</h3>

                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Date:</span>
                                            <span class="form formDateToday">{{dateTime()}}</span>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Signature(s):</span>
                                            
                                            <div class="digital-signature" :class="{error: errorsForm.signature}">
                                                <vueSignature
                                                ref="signature"
                                                :sigOption="digitalSignature.option"
                                                :w="'100%'"
                                                :h="'120px'"
                                                ></vueSignature>

                                                <span @click="clear" class="btn-clear"></span>
                                                
                                                <label>
                                                    <small class="error" v-if="errorsForm.signature">
                                                        {{getMyLanguage("compliance","compliance.error.signature-empty")}}
                                                    </small>
                                                </label>
                                            </div>

                                            <span class="form formSignature" v-html="signnatureName"></span>
                                        </label>
                                    </div>
                                </div>
                                
                                <p class="alert">
                                    It is a criminal offence to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery).
                                </p>
                            </div>
                        </div>

                        <div class="bt-registration">
                            <div class="btn-primary" v-if="!load" @click='validateForm()'>
                                {{ getMyLanguage("compliance", "compliance.modal2fa.title") }}
                            </div>

                            <div class="btn-primary load" v-if="load"></div>
                        </div>
                    </div>


                    <!-- AUTH 2FA -->
                    <AuthKYC @statusChange="handleStatusChange" :dataSource="auth2fa.kyc" v-if="auth2fa.status == 'auth'" />

                    <!-- SUCCESS 2FA -->
                    <div v-if="auth2fa.status == 'success'">
                        <div class="box-registration box-status-2fa success-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.success.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.success.msg") }}</p>
                                <div class="redirecting">{{ getMyLanguage("compliance","compliance.2fa.success.redirecting") }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- RETRY 2FA -->
                    <div v-if="auth2fa.status == 'retry'">
                        <div class="box-registration box-status-2fa retry-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.retry.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.retry.msg") }}</p>

                                <div class="bts">
                                    <div class="btn-primary" @click="auth2fa.status = 'auth'">{{ getMyLanguage("compliance","compliance.2fa.retry.cta") }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- PENDENTE 2FA -->
                    <div v-if="auth2fa.status == 'pending'">
                        <div class="box-registration box-status-2fa pending-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">{{ getMyLanguage("compliance","compliance.2fa.pending.title") }}</h2>
                                <p>{{ getMyLanguage("compliance","compliance.2fa.pending.msg") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    // import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';

    import vueSignature from "vue-signature";
    import AuthKYC from "@/components/AuthKYC.vue"

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header,
            vueSignature,
            AuthKYC
        },

        data(){
            return{
                showContent: false,
                flagSelected: "en",
                load: false,

                compliance: {
                    full_name: "",
                    dob: "",
                    country_id: "",
                    full_address: "",
                    signature: ""
                },

                digitalSignature: {
                    option: {
                        penColor: "rgb(0, 0, 0)",
                        backgroundColor: "rgb(255,255,255)",
                    },
                },

                signnatureName: "",

                errorsForm: {
                    full_name: false,
                    dob: false,
                    country_id: false,
                    full_address: false,
                    signature: false
                },

                errorsGet: {
                    dataCompliance: false
                },

                errorsPost: {
                    signature: false
                },
                
                dataCompliance: {
                    full_name: "",
                    dob: "",
                    country_id: false,
                    full_address: "",
                    signature: "",

                    countryFlag: false,
                    address: {
                        countryFlag: false
                    }
                },

                auth2fa: {
                    status: "form",
                    kyc: {
                        signature: "",
                        account_id: "",
                        check_type: "action"
                    },
                },
                
                dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false
            }
        },
        
        async mounted(){
            console.log(this.$store.state.profile.account_id)
            

            // COUNTRY
            const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            setTimeout(() => {
                this.ordercountries()
            }, 100);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            async loadCompliance(){
                await apiPlatform.get(`/api/v2/platform/compliance/ubo/person/${this.$store.state.profile.account_id}`)
                .then(response => {
                    this.dataCompliance = response.data
                    this.showContent = true
                })
                .catch(error => {
                    this.errorsGet.dataCompliance = error.response.data
                    this.showContent = true

                    handleError(error, this.flagSelected, this.$store.state.profile.account_type);
                })


                // await apiInternational.get('/api/v1/platform/account/international/compliance')
                // .then(response => {
                //     this.dataCompliance = response.data
                //     console.log(this.dataCompliance)
                //     this.showContent = true
                // })
                // .catch(error => {
                //     this.errorsGet.dataCompliance = error.response.data
                //     this.showContent = true

                //     handleError(error, this.flagSelected, this.$store.state.profile.account_type);
                // })
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("issuerCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
                // return moment(value).locale(this.flagSelected).format('ll');
            },

            signatureName(){
                const nameSignature = this.compliance.full_name;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signnatureName = nameSignatureFinished;
            },

            validateError(){
                // if ( this.compliance.full_name.toLowerCase() == this.dataCompliance.full_name.toLowerCase() ){
                //     this.errorsForm.full_name = false
                // }
                
                if ( this.compliance.full_name == this.dataCompliance.full_name ){
                    this.errorsForm.full_name = false

                    this.signatureName()
                }

                if ( this.dateTime(this.compliance.dob) == this.dateTime(this.dataCompliance.dob )){
                    this.errorsForm.dob = false
                }
                
                if ( this.compliance.country_id == this.dataCompliance.country_id ){
                    this.errorsForm.country_id = false
                }

                if ( this.compliance.full_address == this.dataCompliance.full_address ){
                    this.errorsForm.full_address = false
                }
            },

            validateForm(){
                this.isEmpty()
                this.saveDigitalSignature()
                    
                if ( this.compliance.full_name.toLowerCase() != this.dataCompliance.full_name.toLowerCase() || this.compliance.full_name == "" || this.compliance.full_name == null ){
                    this.errorsForm.full_name = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.full_name = false
                }

                if ( this.dateTime(this.compliance.dob) != this.dateTime(this.dataCompliance.dob) || this.compliance.dob == "" || this.compliance.dob == null ){
                    this.errorsForm.dob = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.dob = false
                }

                if ( this.compliance.country_id != this.dataCompliance.country_id || this.compliance.country_id == "" || this.compliance.country_id == null ){
                    this.errorsForm.country_id = true
                    
                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.country_id = false
                }

                if ( this.dataCompliance.full_address !== "" ) {
                    if ( this.compliance.full_address == "" || this.compliance.full_address == null ) {
                        this.errorsForm.full_address = true

                        const errorElement = document.getElementById("error");
                        errorElement.scrollIntoView();
                    } else {
                        this.errorsForm.full_address = false
                    }
                } else {
                    this.errorsForm.full_address = false
                }

                if ( !this.errorsForm.full_name && !this.errorsForm.dob && !this.errorsForm.country_id && !this.errorsForm.full_address && !this.errorsForm.signature ){
                    this.auth2fa.kyc.account_id = this.$store.state.profile.account_id
                    this.auth2fa.kyc.full_name = this.dataCompliance.full_name
                    this.auth2fa.kyc.signature = this.compliance.signature
                    this.auth2fa.status = "auth"
                }
            },

            clear() {
                this.$refs.signature.clear()
            },

            isEmpty() {
                return this.$refs.signature.isEmpty()
            },

            saveDigitalSignature() {
                if (this.isEmpty()) {
                    this.errorsForm.signature = true;
                } else {
                    const svgSignature = this.$refs.signature.save("image/svg+xml");
                    console.log(svgSignature.replace('data:image/svg+xml;base64,', ''));

                    this.errorsForm.signature = false;
                    this.compliance.signature = svgSignature.replace('data:image/svg+xml;base64,', '');
                }

                
                // var _this = this;
                // var svgSignature = _this.$refs.signature.save("image/svg+xml");
                // console.log(svgSignature);
                
                // if ( svgSignature == "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNDkzIDEyMCIgd2lkdGg9IjQ5MyIgaGVpZ2h0PSIxMjAiPjwvc3ZnPg==" ){
                //     this.errorsForm.signature = true
                // } else {
                //     this.errorsForm.signature = false
                //     this.signature.signature = svgSignature.replace('data:image/svg+xml;base64,', '')
                // }

            },

            handleStatusChange(newStatus) {
                this.auth2fa.status = newStatus;
            },

            saveBO(){
                apiPlatform.post(`api/v2/platform/compliance/ubo/person/${this.$store.state.profile.account_id}`, this.compliance)
                .then(response => {
                    console.log(response)

                    this.load = true
                    this.$toast.success(this.getMyLanguage('compliance','compliance.title-success'))
                    
                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/client-space/purchase`
                    }, 5000);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                })


                // apiInternational.post('api/v1/platform/account/international/beneficial_owner', this.signature)
                // .then(response => {
                //     console.log(response)

                //     this.load = true
                //     this.$toast.success(this.getMyLanguage('compliance','compliance.title-success'))
                    
                //     setTimeout(() => {
                //         window.location.href = `/${this.flagSelected}/client-space/purchase`
                //     }, 5000);
                // })
                // .catch(error => {
                //     console.log(error)
                //     this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                // })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            },

            "$store.state.profile": {
                handler() {
                    setTimeout(() => {
                        this.loadCompliance();
                    }, 1000);
                }
            },

            'auth2fa.status'(newStatus) {
                if (newStatus === 'success') {
                    this.saveBO();
                }
            }
        },

        created () {
            // document.title = this.getMyLanguage("seo","title.account.compliance");
            document.title = "Declaration of identity of the beneficial owner";
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-ubo.scss" lang="scss" scoped />