<template>
  <div class="termsPolicies">
        <div id="dne"></div>
        <Header />

        <section class="content-terms">
            <div class="container">
                <div class="box">
                    <div class="scroll-text">
                        <h1 class="title-secondary">{{getMyLanguage("terms-policies","terms-conditions.title")}}</h1>
                        <p><small>LAST UPDATED: JUNE 22, 2022</small></p>

                        <h3>DNE INTERNATIONAL SAGL Terms and Conditions</h3>

                        <p>These Terms and Conditions regulate the purchase and sale, access and use of the Crypto TOKEN “DNE”, whose digital code belongs to <strong>DNE INTERNATIONAL SAGL</strong>, which is responsible for operating and managing the operation.</p>
                        <p>The App DNE ECOSYSTEM belongs to <strong>ECOSISTEMA DNE ETVE SL</strong>, which is responsible for operating and managing the operation of DNEECOSYSTEM.com and App DNE ECOSYSTEM, which use is fully regulated at TCU of DNEECOSYSTEM.com (https://dneecosystem.com/en/terms-and-conditions).</p>
                        
                        <ul>
                            <li>1. General Provisions</li>
                            <li>1.1. Description of Service. DNE TOKEN, a feature within the DNETOKEN.COM web domain and App (“App DNE Ecosystem), and related functionality permits eligible App users (“DNE Users”) to open and keep their digital assets on certain supported digital assets maintained in their Crypto Accounts (“DNE Accounts”).</li>
                            <li>1.2. Contractual Agreement. These terms and conditions, and any others referenced or incorporated herein, constitute a legally binding contract between you, as a DNE User, and the DNETOKEN.COM entity described below (“Relevant Entity”) that provides you access to Crypto Accounts (the “Agreement”). This Agreement governs your access to and use of Crypto wallets. Furthermore, this Agreement governs all rights and obligations relating to the purchase, sale, holding, filing, transaction and operation of the digital DNE TOKEN.</li>
                            <li>1.2.1. Relevant Entity. For purposes of this Agreement, the Relevant Entity providing the Crypto wallet service and related Crypto Accounts is DNE INTERNATIONAL SAGL. Notwithstanding, the Relevant Entity for customers residing in each country’s respective territories, is the same entity that offers you the DNETOKEN.COM App Services via the App DNE Ecosystem. Throughout this Agreement, references to “DNETOKEN.COM,” “we,” “us,” and “our” mean the Relevant Entity, as applicable.</li>                            
                        </ul>
                        
                        <ul>
                            <li>2. Eligibility. To be eligible to use DNETOKEN.COM, you must:</li>
                            <li>- Be an existing App user in compliance with the App’s terms and conditions (“App Agreement”);</li>
                            <li>- Be at least 18 years of age or the legal age of majority in the jurisdiction in which you reside;</li>
                            <li>- Not be a resident or national of North Korea and Iran (“FATF Blacklist”);</li>
                            <li>- Not be subject to any sanctions imposed by any government, including without limitation those imposed by the United Nations, the European Union, any EU country, the UK Treasury, or the U.S. Office of Foreign Assets Control (“OFAC”); and</li>
                            <li>- Read, understand, and agree to our Privacy Notice and Cookie Policy.</li>
                            <li>Please note that we require that the digital assets that you want to allocate to a wallet Account must be purchased from DNETOKEN.COM, its affiliates, or through a DNETOKEN.COM product or service.</li>
                            <li>Notwithstanding your meeting the eligibility criteria above, we reserve the right to refuse your request to use and access the DNETOKEN.COM service, including your ability to open an Account, to suspend your access to Crypto wallet and digital assets, and terminate your wallet Account at any time in our sole discretion. Further, we reserve the right to change the eligibility criteria at any time. If we become aware that you are an ineligible DNE User or otherwise determine that you are in violation of any applicable terms and conditions, we may delete your Account immediately without notice and ban you from using any of our or our Affiliates’ products and services. In addition to the other rights set forth herein and to those that may be available to us, you agree that you will indemnify and hold harmless DNETOKEN.COM and its Affiliates from any legal or monetary consequences that arise from any unauthorized use, including without limitation, all uses associated with an ineligible DNE User.</li>
                        </ul>

                        <ul>
                            <li>3. Crypto DNE TOKEN Plans</li>
                            <li>General. DNE Users may earn rewards on certain supported digital assets that they allocate to and maintain in their wallet Accounts for a defined period of time or an indefinite period of time. Each Earn rewards Plan may maintain only one type of digital asset, DNE TOKEN and remains subject to an amount and terms and conditions established within. DNE Users are permitted to have multiple Earn Plans provided that it accepts its terms and conditions as per the established Agreement herein.</li>
                            <li>Withdrawal. You are not allowed to close a Plan, use, transfer or send digital assets maintained in the same prior to its expiration. In the event that you submit a request to close your wallet Account prior the expiration of a Plan or you still having assets in the same portfolio and wallet, you agree that we shall not be obliged to honor your request until the expiration of the Plan and that any accrued rewards not yet credited to your digital asset wallet will be forfeited.</li>
                        </ul>

                        <ul>
                            <li>4. DNE TOKEN Plans Conditions</li>
                            <li>General. DNE Users may purchase and use  DNE TOKEN under the following rights and conditions:</li>
                            <li>- Lots - The DNE TOKEN is a digital unit. One lot of DNE TOKEN consists of 180 token units.</li>
                            <li>- Private sale – During the “Private Sale” phase there will be available for sale the total amount of 60,000 Lots of 180 units DNE Token, which can be extended to up to 100,000 lots depending on demand, at a price of €90 per lot with a 40% discount corresponding to €54 per lot, in return for keeping the lots in stake for 18 months.</li>
                            <li>On the first stage starting on the June 23, 2022, which will last 90 days (ninety days), the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 40% discount, with the obligation to keep the respective tokens in their digital wallet for a period of 18 months, counting from the date of signature of this agreement of the period of the private sale referred to herein. Wallets will be made available at the start of the ICO. This discount cannot be accumulated with others. The minimum purchase order for each DNE User is 5.400 tokens, corresponding to 30 (thirty) lots and a cumulative maximum of 250 (two hundred and fifty) lots, corresponding to 45.000 tokens for each purchasing entity or person.</li>
                            <li>Use, Transfer or send: During the first stage, you are not allowed to close the Plan, use, transfer, or send DNE TOKEN assets maintained in the same prior to its expiration. In the event that you submit a request to use, transfer or send your DNE TOKEN bought with the above mentioned conditions, being only one or all of the token batches, and / or request to close your wallet Account prior the expiration of this Plan, you agree that we shall return the entire portfolio of the wallet referring to the purchase made at this stage, minus the 40% discount, plus a 25% penalty for breach of contract.</li>
                            <li>- ICO Initial Coin Offering – the public ICO has 3 phases over an overall period of 30 (thirty) days with 3 (three) day intervals as follows:</li>
                            <li>a) During the first phase, there will be available for sale the total amount of 1,500 Lots of 180 units, at a price of €90 per lot.</li>
                            <li>7 days – During the first 7 days (seven days) of the public ICO, the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 20% discount (€72), with the obligation to keep the respective tokens in their digital wallet for a period of 12 months, counting from the end of the period of the public 30 (thirty) day period of the ICO referred to herein. Additionally, a bonus corresponding to 20% of the purchase will be granted and credited in token. This discount and bonus cannot be accumulated with others. The purchase order for each DNE User is 2.700 tokens, corresponding to 15 (fifteen) lots. On this stage, the minimum purchase quantity is 15 lots with a cumulative maximum of 30 lots for each purchasing entity or person.</li>
                            <li>b) During the second phase, there will be available for sale the total amount of 2,500 Lots of 180 units, at a price of €90 per lot.</li>
                            <li>10-17 days – During the next second 7 days (seven days) of the public ICO, the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 12% discount (€79), with the obligation to keep the respective tokens in their digital wallet for a period of 12 months, counting from the end of the period of the public 30 (thirty) day period of the ICO referred to herein. Additionally, a bonus corresponding to 15% of the purchase will be granted and credited in token. This discount and bonus cannot be accumulated with others. The purchase order for each DNE User is 2.700 tokens, corresponding to 15 (fifteen) lots. On this stage, the minimum purchase quantity is 15 lots with a cumulative maximum of 50 lots for each purchasing entity or person.</li>
                            <li>c) During the third phase, there will be available for sale the total amount of 2,000 Lots of 180 units, at a price of €90 per lot.</li>
                            <li>20-27 days – During the next third 7 days (seven days) of the public ICO, the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 5% discount (€85), with the obligation to keep the respective tokens in their digital wallet for a period of 12 months, counting from the end of the period of the public 30 (thirty) day period of the ICO referred to herein. Additionally, a bonus corresponding to 10% of the purchase will be granted and credited in token. This discount and bonus cannot be accumulated with others. The purchase order for each DNE User is 2.700 tokens, corresponding to 15 (fifteen) lots. On this stage, the minimum purchase quantity is 15 lots with a cumulative maximum of 70 lots for each purchasing entity or person.</li>
                            <li>Use, Transfer or send: During these phases, you are not allowed to close the Plan or use, transfer or send DNE TOKEN assets maintained in the same prior to its expiration. In the event that you submit a request to sale your DNE TOKEN bought with the above mentioned conditions, being only one or all of the token batches, and / or request to close your wallet Account prior the expiration of this Plan, you agree that we shall return the entire portfolio of the wallet referring to the purchase made at these stages, minus the discount and bonus given, plus a 25% penalty for breach of contract.</li>
                            <li>- DNE Box – After the ICO period and its term, the DNE User will have the right to purchase the tokens - DNE TOKEN – with a bonus corresponding to 20% of the purchase which will be granted and credited in token, with the obligation to keep the respective tokens in their digital wallet for a period of 12 months, counting from the purchase date referred to herein. This bonus cannot be accumulated with others. The maximum purchase order for each DNE User is 5.400 tokens, corresponding to 30 (thirty) lots. At this stage, the minimum purchase quantity is 1 lot with a cumulative maximum of 30 lots per month for each purchasing entity or person.</li>
                            <li>Use, Transfer or send. You are not allowed to close the Plan or use, transfer or send DNE TOKEN assets maintained in the same prior to its expiration. In the event that you submit a request to sale your DNE TOKEN bought with the above mentioned conditions, being only one or all of the token batches, and / or request to close your wallet Account prior the expiration of this Plan, you agree that we shall return the entire portfolio of the wallet referring to the purchase made at this stage, minus the bonus given, plus a 25% penalty for breach of contract.</li>
                            <li>No Automatic Renewal. Upon expiration of a Plan as described above, the digital assets allocated to it will automatically be deallocated and transferred to your corresponding digital asset wallet in the App DNE Ecosystem and DNETOKEN.COM, and no other plans or special conditions may be renewed or assigned to the same DNE tokens.</li>                            
                        </ul>

                        <ul>
                            <li>5. Rights of DNETOKEN.COM. We reserve the right to and at any time, without liability to you to:</li>
                            <li>- Refuse your request to open an Account;</li>
                            <li>- Change the eligibility criteria for registration or use of website DNETOKEN.COM and App DNE Ecosystem, including the asset wallet;</li>
                            <li>- Close, suspend, limit, restrict, or terminate your access to Crypto wallet, the App DNE Ecosystem, or other products and services provided by our Affiliates;</li>
                            <li>- Close, suspend, limit, restrict, or terminate your access to Crypto wallet, the App DNE Ecosystem, or other products and services provided by ourselves or any other of our Affiliates if you remain inactive, without any movement/transaction and/or access and without any digital asset for a period equal to or greater than 3 months; and</li>
                            <li>- Change, update, remove, cancel, suspend, disable, or discontinue any features, component, content, incentive, or plan of rewards provided that we may not change the rewards rate, maturity date, or other material terms that impact your rewards accrual during the duration of any active Plan you have.</li>
                        </ul>

                        <ul>
                            <li>6. Prohibited Activities. By accessing and using .com and App DNE Ecosystem, you agree that you will not do any of the following:</li>
                            <li>- Engage in any activity that would violate or assist in the violation of any law, statute, regulation, or ordinance;</li>
                            <li>- Violate or breach any terms or conditions in this Agreement, any other agreement or set of applicable terms and conditions, our Privacy Notice, or our Cookie Policy;</li>
                            <li>- Provide us with any false, inaccurate, or misleading information or otherwise attempt to defraud us;</li>
                            <li>- Attempt to gain unauthorized access to our network, system, or websites or wallet Accounts other than your own;</li>
                            <li>- Decompile, disassemble, or otherwise reverse engineer our App, asset wallet, rewards plan or any other related product, program, or system or in any way infringe on our intellectual property rights including, but not limited to, copyrights, patents, trademarks, or trade secrets;</li>
                            <li>- Engage in any form of data mining, indexing, scraping, surveying, or otherwise with respect to our App, domain, wallet, or any other related product, program, or system; or</li>
                            <li>- Unduly burden or hinder the functionality and/or operation of any aspect of our App, domain, asset wallet, or any other product, program, or system offered, maintained, or provided by us or our affiliates.</li>
                        </ul>

                        <ul>
                            <li>7. Suspension and Termination</li>
                            <li>Account. We reserve the right to suspend, restrict, or terminate your wallet Account if:</li>
                            <li>- We are required to do so by a court order, government order, or a facially valid subpoena;</li>
                            <li>- We reasonably suspect you of engaging in Prohibited Activities as described in this Agreement; or</li>
                            <li>- We reasonably suspect you of otherwise being in violation of this Agreement or other applicable terms and conditions for products and services offered by DNETOKEN.COM or its affiliates.</li>
                            <li>- As the owner of the WebSite, may, at any time, prohibit its access to third parties, namely for the purpose of repair or maintenance. Additionally, DNE INTERNATIONAL SAGL will have the right to terminate it at any time in the event of any circumstance that, in its opinion, justifies it.</li>
                            <li>Upon termination of your wallet Account, you are entitled to recover your digital assets in accordance with the App’s terms and conditions and this Agreement, unless we are prohibited from doing so by law or a court order or where we reasonably suspect that such funds were obtained through fraud or any unlawful means or in connection with any criminal activities.</li>
                            <li>This Agreement terminates immediately upon you closing your Account or our termination of your Account, but such termination shall not prevent any party from seeking remedies against any other party for any breach of this Agreement occurring prior to termination.</li>
                        </ul>

                        <ul>
                            <li>8. Contact</li>
                            <li>If you have any questions regarding DNETOKEN.COM or need assistance in connection with the suspension or termination of your wallet Account, please contact us at SUPPORT@DNETOKEN.COM.</li>
                        </ul>

                        <ul>
                            <li>9. Miscellaneous Provisions</li>
                            <li>Indemnification. You will defend, indemnify, and hold harmless DNETOKEN.COM, its affiliates, and each of their respective employees, officers, directors, and representatives from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or related to your breach of this Agreement, your violation of any statute, regulation, or ordinance, or the rights of any third party.</li>
                            <li>LIMITATION OF LIABILITY</li>
                            <li>NOTWITHSTANDING ANY PROVISIONS TO THE CONTRARY IN THIS AGREEMENT OR OTHERWISE MADE AVAILABLE TO YOU FROM TIME TO TIME, IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR ANY TYPE OF INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, REPLACEMENT OF GOODS, LOSS OF TECHNOLOGY, LOSS OF DATA, OR INTERRUPTION OR LOSS OF USE OF SERVICE OR EQUIPMENT EVEN IF SUCH PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER ARISING UNDER THEORY OF CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.</li>
                            <li>Except as provided in this Agreement and to the extent permitted by law, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person including, without limitation, any warranties regarding quality, suitability, merchantability, fitness for a particular purpose, title, non-infringement, or otherwise, regardless of any course of dealing, custom, or usage of trade, of any service or any goods provided incidental to DNETOKEN.COM and App DNE Ecosystem under this Agreement.</li>
                            <li>Our liability in respect of representations and warranties that cannot be excluded under this Agreement is limited to, at our option, any one of resupplying, replacing, or repairing, or paying the cost of thereof, the services in respect of which the breach occurred.</li>
                            <li>IN NO EVENT WILL OUR AGGREGATE LIABILITY FOR ANY LOSS OR DAMAGE ARISING IN CONNECTION WITH CRYPTO DNE TOKEN EXCEED EUR €100 OR THE TOTAL FEES THAT YOU PAID US FOR ITS USE DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY. THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</li>                            
                        </ul>

                        <ul>
                            <li>10. Tax Obligations</li>
                            <li>Uncertainty in the treatment of taxes related to digital assets may expose you to future or unknown tax obligations. It is your sole responsibility to determine what taxes, if any, arise from your use of DNE TOKEN. Further, you are solely responsible for reporting and paying any applicable taxes. Except to the extent that we are required by applicable law, we will not determine whether, or to what extent, you are liable for taxes, report them, or withhold them.</li>
                        </ul>

                        <ul>
                            <li>11. Relationship</li>
                            <li>DNETOKEN.COM is not your trustee, investment advisor, or fiduciary. By providing the App DNE Ecosystem and wallet service to you, DNETOKEN.COM does not become a trustee, investment advisor, or form any sort of fiduciary relationship with you. We have no trust or other obligations with respect of your digital assets other than those expressly specified in this Agreement.</li>
                        </ul>

                        <ul>
                            <li>12. Entire Agreement</li>
                            <li>This Agreement (together with the Privacy Notice and Cookie Policy) represents the entire agreement between you and us with respect to DNETOKEN.COM and supersedes all prior representations, understandings, agreements, or communications between you and us, whether written or verbal, including any statements published in our whitepaper regarding DNE TOKEN.</li>
                        </ul>

                        <ul>
                            <li>13. Amendments</li>
                            <li>We may amend or modify this Agreement from time to time by posting the updated Agreement on the App DNE Ecosystem or in accordance with the App terms and conditions and such amendment or modification shall take effect immediately. Your continued use of the wallet constitutes your acceptance of the updated Agreement; if you do not agree with the updated Agreement, your sole and exclusive remedy is to discontinue use of your asset wallet and DNE TOKEN.</li>
                        </ul>

                        <ul>
                            <li>14. Severability</li>
                            <li>If any provision, in whole or in part, of this Agreement shall be determined to be invalid or unenforceable by a court of competent jurisdiction or a governmental body, such provision shall be changed and interpreted to accomplish its objectives to the greatest extent possible under applicable law and the validity and enforceability of any other provision of this Agreement shall not be affected.</li>
                            <li>All provisions of this Agreement which by their nature extend beyond its termination, including, without limitation, provisions pertaining to suspension and termination, shall survive such termination.</li>
                            <li>The delay or failure of one party to require performance of any provision of this Agreement shall not constitute a waiver of that party’s right to require performance at any time thereafter. Further, the waiver of one party to seek recovery for the other party’s non- performance shall not constitute a waiver by the former party to seek recovery for any subsequent non-performance.</li>
                        </ul>

                        <ul>
                            <li>15. Assignment</li>
                            <li>You may not assign, delegate, or transfer any rights or obligations under this Agreement without our prior written consent. Any such attempted assignment, delegation, or transfer shall be null and void. We reserve the right to assign our rights without restriction, including without limitation to affiliates or subsidiaries, or to any successor in interest of any business associated with the App DNE Ecosystem or DNETOKEN.COM.</li>
                        </ul>

                        <ul>
                            <li>16. Third Party Rights</li>
                            <li>Other than the affiliates of DNETOKEN.COM, a person who is not a party to this Agreement shall have no right under this Agreement.</li>
                        </ul>

                        <ul>
                            <li>17. Governing Law</li>
                            <li>This Agreement shall be governed by, construed, and enforced in accordance with the laws of Switzerland. Any dispute, controversy, difference, or claim arising out of or relating to this Agreement including the existence, validity, interpretation, performance, breach, or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to the same shall be referred to and finally resolved by a court of competent jurisdiction sitting in Lugano and must be conducted in English.</li>
                        </ul>

                        <ul>
                            <li>18. Force Majeure</li>
                            <li>We shall not be liable for any delay, error, interruption, or failure to perform any obligation under this Agreement where the delay or failure is directly or indirectly resulting from any cause beyond our control including, but not limited to i) acts of God, nature, court, or government; ii) failure or interruption in private or public telecommunications networks, communication channels, or information systems; iii) acts or omissions of a party for whom we are not responsible; iv) delay, failure, interruption in, or unavailability of third-party services and sites; v) strikes, lockouts, labor disputes, war, terrorist acts, or riots; and vi) viruses, malware, other malicious computer code, or the hacking of any part of the App DNE Ecosystem or DNETOKEN.COM.</li>
                            <li>You understand and agree that your use of the App DNE Ecosystem and DNETOKEN.COM is at your own risk. This section is not exhaustive and does not disclose all the risks associated with digital assets and the use of our products and services. You should carefully consider whether such use is suitable for you in light of your circumstances and financial resources.</li>
                        </ul>

                        <ul>
                            <li>19. Disclosures</li>
                            <li>The regulatory status of digital assets is currently unsettled, varies among jurisdictions, and is subject to significant uncertainty. It is possible that in the future, certain laws, regulations, policies, or rules relating to digital assets, blockchain technology, or blockchain applications may be implemented which would directly or indirectly affect or restrict the services that we provide to you.</li>
                            <li>YOU ARE RESPONSIBLE FOR DETERMINING WHETHER THE USE OF DNETOKEN.COM AND ANY OF OUR RELATED SERVICES IS LEGAL IN YOUR JURISDICTION AND YOU SHALL NOT USE THEM SHOULD SUCH USE BE ILLEGAL IN YOUR JURISDICTION. IF YOU ARE UNCERTAIN, PLEASE SEEK INDEPENDENT LEGAL ADVICE.</li>
                            <li>We may be forced to suspend, discontinue, or to change aspects of DNETOKEN.COM and any of our services in any jurisdictions without notice and for whatever reason if demanded by regulatory authorities. In such instances, digital assets in your wallet Account may be frozen for an indefinite period of time until the matter is resolved.</li>
                        </ul>
                        
                        <ul>
                            <li>20. Cybersecurity</li>
                            <li>Digital assets may be subject to expropriation, theft and/or fraud; hackers and other malicious groups or organizations may attempt to interfere with our network and/or system in various ways including malware attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing, and spoofing which may result in the loss of your digital assets or the loss of your ability to access or control the same. In such an event, we do not guarantee any remedy, refund, or compensation.</li>
                        </ul>

                        <ul>
                            <li>21. Source Code Weakness.</li>
                            <li>There is a risk that the DNETOKEN.COM, App DNE Ecosystem or any of our products and services may unintentionally include weakness or bugs in the source code which may adversely affect you.</li>
                        </ul>

                        <ul>
                            <li>22. Insurance.</li>
                            <li>The digital assets held in your wallet Account are not protected by any government - backed insurance scheme including, but not limited to, the Central Bank or any other Insurance company.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header
        },

        methods: {
            getMyLanguage(group, code) {
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            }
        },

        created() {
            document.title = this.getMyLanguage("seo", "title.termsConditions");
            document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.termsConditions");
        }
    }
</script>

<style src="./custom-terms.scss" lang="scss" scoped />
